.header {
  display: flex;
  align-items: center;
  height: 100px;
  z-index: 334;
  position: relative;
  background-color: white;
}

.header-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
  width: 248px;
}

.header-left img {
  height: 24px;
  position: fixed;
  top: 38px;
}

.header-search {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 48px;
}

.hs-close-icon {
  position: absolute;
  right: 72px;
  display: none;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

input[type="text"],
input[type="email"],
input[type="password"] {
  -webkit-appearance: none;
}

.header-search-input {
  border: none;
  font-size: 16px;
  margin-left: 14px;
  flex: 1;
  padding-left: 12px;
  -webkit-appearance: none;
  @include placeholder {
    color: #bbbebf;
  }
}

.search-results {
  position: absolute;
  top: 85px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.08);
  width: calc(100% - 48px);
  padding: 18px 24px;
  box-sizing: border-box;
  display: none;
}

.header-search-active {
  .hs-browse-icon {
    display: none;
  }

  .header-search-input {
    margin-left: 0;
    padding-left: 0;
  }

  .hs-close-icon {
    display: block;
    cursor: pointer;
  }

  .search-results {
    display: block;
  }
}

.search-results-section {
  margin-bottom: 16px;
}

.srs-title {
  color: #bbbebf;
}

.srs-list {
  margin-top: 16px;
}

.result {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #1e1f20;
  font-size: 16px;
  margin-bottom: 16px;
}

.result svg {
  margin-right: 16px;
  width: 24px;
}

.header-right {
  display: flex;
  height: 100%;
  align-items: center;
}

.hs-browse-icon {
  position: absolute;
  left: 0;
}

.header-and-picture {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-pic {
  width: 36px;
  height: 36px;
  background-size: cover;
}

.name-and-drop {
  margin-left: 16px;
  position: relative;
  cursor: pointer;
}

.header-name {
  font-size: 14px;
}

.name-drop-arrow {
  margin-left: 21px;
  cursor: pointer;
}

.nad-dropdown {
  position: absolute;
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.08);
  margin-top: 24px;
  width: 170px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  z-index: 2;
}

.nad-dropdown a {
  display: flex;
  padding: 14px 20px;
  color: #1e1f20;
  text-decoration: none;
  font-size: 12px;
  align-items: center;
  &:hover {
    font-weight: bold;
  }
}

.nad-dropdown a svg {
  margin-right: 16px;
}

.nad-top {
  border-bottom: 1px solid #e5e7ea;
}

.nad-middle {
  border-bottom: 1px solid #e5e7ea;
}

.header-notifs {
  margin-left: 32px;
  cursor: pointer;
  width: 41px;
  height: 41px;
}

.standard-circle-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  appearance: none;
  background-color: transparent;
  padding: 0;
  &:hover {
    border-color: #bbbebf;
  }
}

.play-button-circle,
.standard-circle-button-circle {
  border: 2px solid #e2e5e8;
}

.white-play,
.white-pause {
  color: white;
}

.white-pause {
  color: white;
}

.my-arrows {
  display: flex;
  align-items: center;
}

.my-arrows,
.bcr-bottom,
.bcr-bottom-right {
  .standard-circle-button:first-of-type {
    margin-right: 8px;
  }
}

.active-play {
  span {
    display: block !important;
  }
  width: unset;
  border-radius: 26px;
  background-color: #30a3ff;
  color: white;
  padding: 0 16px;
  border-color: #30a3ff !important;
  svg rect,
  svg use,
  svg path {
    fill: white;
    stroke: white;
  }
  svg {
    color: white;
    margin-right: 8px;
  }
}

.play-button {
  transition: 200ms ease all;
  span {
    display: none;
    // font-size: 12px;
  }
  @media (min-width: 768px) {
    &:hover {
      span {
        display: block !important;
      }
      width: unset;
      border-radius: 26px;
      background-color: #30a3ff;
      color: white;
      padding: 0 16px;
      border-color: #30a3ff !important;
      svg use,
      svg path {
        fill: white;
        stroke: white;
      }
      svg {
        color: white;
        margin-right: 8px;
      }
    }
  }
}

.read-button {
  transition: 200ms ease all;
  text-decoration: none;
  margin-right: 0 !important;
  span {
    display: none;
    text-decoration: none;
    font-size: 12px;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  @media (min-width: 768px) {
    &:hover {
      span {
        display: block;
        text-decoration: none;
      }
      width: unset;
      border-radius: 26px;
      background-color: #1e1f20;
      color: white;
      padding: 0 16px;
      border-color: #1e1f20 !important;
      svg use,
      svg path {
        fill: white;
        stroke: white;
      }
      svg {
        color: white;
        margin-right: 8px;
      }
    }
  }
}

.flip-svg svg {
  transform: rotate(180deg);
}

.app-content {
  display: flex;
  min-height: 100vh;
  padding-top: 24px;
}

.no-scroll {
  min-height: unset;
}

.side-header {
  position: fixed;
  height: calc(100vh - 125px);
  perspective: initial !important;
  transition: initial;
  transform: initial !important;
  max-width: 170px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.standard-page {
  flex: 1;
  padding-bottom: 64px;
  margin-left: 230px;
}

.side-header-section {
  position: relative;
  margin-bottom: 24px;
}

h4.shs-discover {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: #bbbebf;
  text-transform: uppercase;
}

.shs-section-links a {
  display: flex;
  font-size: 14px;
  color: #1e1f20;
  text-decoration: none;
  height: 40px;
  margin-bottom: 10px;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  .shs-svg {
    font-size: 16px;
  }
  .shs-svg,
  .shs-svg path {
    fill: #1e1f20;
    stroke: #1e1f20;
  }
  &:hover {
    background-color: #f4f6f8;
    color: #30a3ff !important;
    .shs-svg,
    .shs-svg path {
      stroke: #30a3ff !important;
    }
  }
}

.fill-svg:hover {
  .shs-svg,
  .shs-svg path {
    fill: #30a3ff !important;
  }
}

.shs-svg {
  margin-right: 16px;
}

.active-shs-link {
  background-color: #f4f6f8;
  color: #30a3ff !important;
  .shs-svg,
  .shs-svg path {
    stroke: #30a3ff !important;
  }
}

.active-shs-link.fill-svg {
  .shs-svg,
  .shs-svg path {
    fill: #30a3ff !important;
  }
}

.shs-section-links {
  .no-fill-svg,
  .active-shs-link.no-fill-svg {
    svg,
    path {
      fill: unset !important;
    }
  }
}

.playlist-details {
  display: none;
}

.for-you-scroller {
  display: flex;
  overflow: scroll;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: absolute;
  width: calc(100% - 310px);
}
.for-you-scroller::-webkit-scrollbar {
  display: none; // Safari and Chrome
}

#fyh-section {
  margin-bottom: 248px;
  overflow: visible;
}
#fyh-scroller {
  padding-bottom: 250px;
  margin-bottom: -250px;
}

.for-you-item {
  width: 350px;
  text-align: center;
  text-decoration: none;
}

.browse-item p,
.for-you-item p {
  font-size: 20px;
  font-weight: 900;
  text-decoration: none;
  color: #212126;
  margin-top: 8px;
  line-height: 24px;
}

.browse-item {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  flex-grow: 1;
  flex-shrink: 1;
  &:hover {
    .fyi-image,
    .browse-image {
      opacity: 0.8;
    }
  }
}

.for-you-scroller .for-you-item:first-of-type {
  margin-left: 0;
}

.fyi-image,
.browse-image {
  height: 177px;
  width: 100%;
  border-radius: 14px;
  background-size: cover;
  background-position: center;
  align-self: flex-start;
}

.browse-item {
  margin-left: 0;
}

.browse-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;
}

.standard-section {
  overflow: hidden;
  width: 100%;
  margin-bottom: 64px;
}

.standard-page {
  overflow-x: hidden;
}

.for-you-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.notifications {
  position: absolute;
  right: 0;
  top: 100px;
  display: flex;
  flex-direction: column;
  z-index: 222;
}

.notification {
  background-color: #30a3ff;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-radius: 10px;
  margin-bottom: 24px;
  justify-content: space-between;
  width: 425px;
  max-width: 100%;
  box-sizing: border-box;
}

.notif-content {
  font-size: 12px;
  font-weight: 500;
  padding: 24px 48px 24px 16px;
  line-height: 14px;
}

.notif-content p {
  font-size: 12px;
}

.notif-title {
  margin-bottom: 12px;
}

.notif-ok {
  border-left: 1px solid rgba(255, 255, 255, 0.32);
  padding-left: 24px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.load-more {
  margin-top: 48px;
  text-align: center;
  padding: 12px 42px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #dee2e5;
  border-radius: 20px;
  will-change: opacity;
  &:hover {
    opacity: 0.8;
  }
}

.lm-holder {
  display: flex;
  justify-content: center;
}

.support-button {
  width: 170px;
  background-color: #30a3ff;
  border-radius: 20px;
  position: relative;
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: 500;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 430px;
  position: absolute;
  bottom: 80px;
  &:hover {
    opacity: 0.8;
  }
}

.support-button svg {
  position: absolute;
  left: 14px;
}

.player {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 420px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  &:hover {
    .player-hover-section {
      display: block;
    }
  }
}

.player-preview {
  border-radius: 32px 32px 0 0;
  background-color: #1e1f20;
  padding: 24px 32px;
  box-sizing: border-box;
  color: white;
  z-index: 1;
}

.pp-top {
  display: flex;
  align-items: center;
}

.pp-active-title {
  font-size: 16px;
  font-weight: 500;
}

.wave-holder {
  flex: 1;
  text-align: center;
}

.pp-bottom {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pp-time {
  font-size: 14px;
}

.pp-progress {
  font-size: 12px;
  position: absolute;
  left: 5px;
  bottom: 5px;
}

.pp-navs,
.chapter-left {
  display: flex;
  align-items: center;
  [data-test-id="CircularProgressbarWithChildren"] {
    display: flex;
    align-items: center;
    > div {
      display: flex;
    }
  }
  .CircularProgressbar {
    box-sizing: content-box;
    padding: 0 40px;
    color: white;
    height: 41px;
    width: 41px;
  }
  .CircularProgressbar-trail,
  .CircularProgressbar-path {
    stroke-width: 4;
  }
  [data-test-id="CircularProgressbarWithChildren__children"] {
    margin-top: unset !important;
    top: 0;
    svg {
      cursor: pointer;
    }
  }
}

.pp-nav-play {
  width: 11px;
  cursor: pointer;
}

.player-hover-section {
  background-color: #f4f6f8;
  padding: 24px 32px 48px;
  position: relative;
  top: 24px;
  border-radius: 32px 32px 0 0;
  display: none;
}

.player-mobile-hover-section {
  background-color: #f4f6f8;
  padding: 24px 32px 48px;
  position: relative;
  top: 24px;
  border-radius: 32px 32px 0 0;
  display: none;
}

.player-mobile-hover-section .arrowup {
  padding: 15.5px 13px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) rotate(180deg) !important;
}

.mpb-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
}

.arrowup {
  margin: 0 auto;
  display: block;
  cursor: pointer;
}

.phs-content {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.phs-cover-details {
  display: flex;
  align-items: center;
}

.phs-cover {
  height: 68px;
  width: 45px;
  background-size: cover;
  border-radius: 3px;
}

.phs-details {
  margin-left: 16px;
}

.phs-title {
  font-weight: bold;
  margin-bottom: 9px;
}

.phs-author {
  color: #d8d8d8;
}

.player-open-section {
  background-color: #1e1f20;
  color: white;
  padding: 0 32px;
  max-height: 0;
  transition: 500ms ease all;
}

.pos-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

.chapter-list {
  max-height: 0;
  margin-top: 0;
  transition: 500ms ease all;
}

.player-open {
  .player-hover-section {
    display: block;
  }
  .player-open-section {
    max-height: 50vh;
    padding: 0 32px 24px;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #1e1f20;
    }

    &::-webkit-scrollbar {
        width: 12px;
        background-color: #1e1f20;
    }

    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #555;
    }
  }
  .chapter-list {
    margin-top: 36px;
    max-height: 640px;
  }
  .arrowup {
    transform: rotate(180deg);
  }
}

.chapter {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chapter-left {
  width: 60px;
  justify-content: flex-start;
}

.chapter-name {
  flex: 1;
  cursor: pointer;
}

.chapter-left .CircularProgressbar {
  padding: 0;
}

.chapter-length {
  opacity: 0.4;
}

.active-chapter {
  .chapter-name {
    color: #30a3ff;
  }
  .chapter-length {
    opacity: 1;
  }
}

.notifications-section {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  height: 100vh;
  box-sizing: border-box;
  display: none;
}

.notifications-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #777777;
  opacity: 0.7;
}

.notifications-tab {
  position: absolute;
  right: 0;
  width: 450px;
  background-color: white;
  height: 100vh;
  padding: 120px 16px 24px;
  box-sizing: border-box;
  font-size: 20px;
  overflow: scroll;
}

.show-notification-tab .notifications-section {
  display: block;
}

.notifications-tab .notif-title {
  padding-left: 16px;
}

.notification-item {
  display: flex;
  background-color: #f4f6f8;
  border-radius: 8px;
  margin-bottom: 12px;
  align-items: center;
}

.nitem-left {
  font-size: 16px;
  padding: 20px 24px;
  flex: 1;
}

.nitem-title {
  font-weight: 600;
  flex: 1;
}

.nitem-time {
  font-size: 14px;
  color: #bbbebf;
  margin-top: 12px;
}

.nitem-title a {
  color: #30a3ff;
  text-decoration: none;
}

.nitem-cover {
  height: 75px;
  width: 50px;
  background-size: cover;
  margin-right: 5px;
  border-radius: 3px;
}

.notification-update {
  display: flex;
  border: 1px solid #ebeef0;
  box-sizing: border-box;
  margin-bottom: 12px;
  align-items: center;
  border-radius: 8px;
  svg {
    padding-left: 16px;
  }
}

.nitem-link {
  padding-right: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #30a3ff;
  text-decoration: none;
}

.notif-items {
  margin-top: 36px;
}

.flip {
  transform: rotate(180deg);
}

.book-card-menu-mobile-holder {
  display: none;
}

@mixin transition {
  transition: 0.3s all ease;
}

.mobile-buttons {
  width: 170px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  height: calc(100vh - 235px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-menu-logo {
  font-size: 3em;
}


.instagram-btn {
  position: relative;
  margin-bottom: 12px;
  width: 170px;
  height: 40px;
  background: linear-gradient(45deg, #FFC926 9.15%, #FF5346 49%, #C536DE 100%);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-left: 18px;
  padding-right: 12px;
  justify-content: space-between;
  @include transition;
  &:focus {
    outline: none;
  }

  .wrapper {
    text-align: center;
    position: absolute;
    z-index: 0;
    background-color: #fff;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-left: 20px;
    border-radius: 25px;
    left: 1px;
    @include transition;
  }

  .instagram-btn-icon {
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    @include transition;

    .icon-wrap {
      transform: translateY(-1px);
      position: relative;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        @include transition;

        * {
          opacity: 1;
          @include transition;
        }

        &:nth-child(1) {
          opacity: 1;
        }

        &:nth-child(2) {
          opacity: 0;
        }
      }
    }
  }
  .arrow {
    z-index: 1;
  }
  .label {
    z-index: 0;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #1E1F20;
    @include transition;
  }
  &:focus,
  &:hover {
    .wrapper {
      background-color: transparent;
    }

    .label {
      color: #fff;
    }

    .instagram-btn-icon {
      svg {
        &:nth-child(1) {
          opacity: 0;
        }

        &:nth-child(2) {
          opacity: 1;
        }
      }
    }
  }
}

.socials-btn {
  align-items: center;
  a {
    text-decoration: none;
    margin-bottom: 12px;
    &:first-child {
      margin-right: 24px;
    }
    padding-left: 18px;
    padding-right: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DEE2E5;
    border-radius: 24px;
    width: 170px;
    height: 40px;
    .socials-btn-icon {
      display: flex;
      align-items: center;
    }
    .label {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #1E1F20;
    }
    .arrow {
      display: flex;
      align-items: center;
    }
    @include transition;
    &:focus {
      outline: none;
    }
    &:hover,
    &:focus {
      border-color: rgba(#DEE2E5, .4);
      background-color: rgba(#DEE2E5, .4);
    }
  }
}
