@media (max-width: 1440px) {
  // .book-card-cover {
  //   width: 134px;
  // }

  // .book-card-grid {
  //   grid-template-columns: repeat(2, 50%);
  // }

  // .book-card-grid .book-card-holder:nth-child(3n - 1) {
  //   justify-content: flex-start;
  // }

  // .book-card-grid .book-card-holder:nth-child(3n - 3) {
  //   justify-content: flex-start;
  // }
  .fyi-image,
  .browse-image {
    // width: 241px;
    height: 129px;
    border-radius: 14px;
  }

  .category-splash {
    height: 126px;
  }

  .author-page {
    flex-direction: column;
  }

  .author-left {
    width: 100%;
    border-radius: 24px 0 0 24px;
    padding-right: 0;
    position: absolute;
    max-width: calc(90% - 180px);
  }

  .author-details {
    margin-top: 144px;
  }

  .author-items {
    flex-direction: row;
    overflow: scroll;

    .author-item:last-of-type {
      padding-right: 40px;
    }
  }

  .author-item {
    flex-direction: column;
    margin-right: 18px;
    min-width: 80px;
    text-align: center;
    margin-bottom: 0;
  }

  .author-pic {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .author-item a {
    display: flex;
    flex-direction: column;
  }

  .author-details-link {
    display: none;
  }

  .ai-name {
    line-height: 1.35;
  }

  .author-details {
    padding: 48px 0;
  }

  .book-card-holder-long .bcr-bottom-right {
  }

  .bcr-bottom-left {
    margin-top: 0;
  }

  .bcr-bottom img {
    margin-right: 8px;
  }

  .book-card-holder-long .details-svg {
    margin-left: 16px;
  }
  .np-right-buttons {
    display: none;
  }

  .pop-books-scroller .book-card-holder {
    margin-right: 48px;
  }

  .profile-change {
    padding: 0 16px;
  }
  .np-right {
    flex: 1;
  }

  .playlist-input {
    width: unset;
  }

  .np-right .np-right-buttons {
    display: flex;
  }
  .pop-books-scroller .book-card-holder {
    min-width: 50%;
    display: flex;
    flex: 1;
    margin-right: 0;
    &:nth-child(3n - 1) {
      justify-content: flex-start;
    }
    &:nth-child(3n - 3) {
      justify-content: flex-start;
      margin-right: 0;
    }
    &:nth-child(2n - 2) {
      justify-content: flex-start;
      margin-right: 50px;
    }
  }
  .playlist-items {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-gap: 36px 0;
  }
  .playlist-preview-box {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    position: relative;
    justify-self: center;
    &:nth-child(4n - 3) {
      justify-self: center;
    }
    &:nth-child(4n - 4) {
      justify-self: center;
    }
    &:nth-child(3n - 2) {
      justify-self: flex-start;
    }
    &:nth-child(3n - 3) {
      justify-self: flex-end;
    }
    &:hover {
      .playlist-preview {
        opacity: 0.5;
      }
      .pti-trash {
        display: flex;
      }
    }
  }
}
