.category-splash {
  width: 100%;
  height: 177px;
  border-radius: 24px;
  margin-bottom: 64px;
  background-size: cover;
}

.category-section-header {
  display: flex;
  justify-content: space-between;
}

.sort-toggle {
  display: flex;
  height: 40px;
  border: 1px solid #ebeef0;
  border-radius: 20px;
}

.sort-toggle div {
  width: 108px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

.active-sort-toggle {
  background-color: #1e1f20;
  color: white;
  opacity: 1 !important;
}

.author-page {
  display: flex;
}

.author-left {
  width: 337px;
  border-radius: 24px 24px 0 0;
  background-color: #f4f6f8;
  padding: 24px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: calc(90% - 180px);
}

.author-left::-webkit-scrollbar {
  display: none; // Safari and Chrome
}

.author-items {
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.author-items::-webkit-scrollbar {
  display: none; // Safari and Chrome
}

.author-item {
  display: flex;
  text-decoration: none;
  margin-bottom: 24px;
  align-items: center;
  color: #1e1f20;
  position: relative;
  &:hover {
    font-weight: bold;
    .author-menu {
      font-weight: initial;
    }
  }
}

.author-item a {
  flex: 1;
  text-decoration: none;
  color: #1e1f20;
  display: flex;
  align-items: center;
}

.author-pic {
  height: 40px;
  width: 40px;
  margin-right: 12px;
  border-radius: 50%;
}

.author-menu {
  top: 0;
}
.author-details-link {
  cursor: pointer;
  padding: 14px 18px;
  position: relative;
  right: -20px;
}

.author-book-cards {
  display: flex;
  flex-direction: column;
}

.profile-header {
  height: 148px;
  width: 100%;
  background-color: #f4f6f8;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 54px 40px;
  box-sizing: border-box;
}

.profile-header-left {
  display: flex;
  align-items: center;
}

.p-profile-picture {
  background-size: cover;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 24px;
}

.p-profile-picture svg {
  display: none;
}

.profile-header-left h1 {
  margin: 0;
}

.p-profile-picture:hover svg {
  display: block;
}

.p-profile-picture:hover {
  opacity: 0.7;
}

.profile-header-right {
  display: flex;
  align-items: center;
}

.standard-button.logout-button {
  height: 40px;
  width: 170px;
  border-radius: 20px;
  background-color: #1e1f20;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px !important;
}

.logout-button svg {
  position: absolute;
  left: 20px;
}

.phr-dets,
.cat-dets {
  margin-left: 24px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
}

.cat-dets {
  border: 1px solid #dee2e5;
}

.csh-right {
  display: flex;
}

.profile-change {
  margin-top: 64px;
  padding: 0 64px;
}

.profile-change-section {
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
}

.profile-change-section h2 {
  font-size: 20px;
}

.h-form {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
}

.h-form-group {
  width: 29.5%;
  margin-right: 1%;
}

label {
  font-size: 14px;
  color: #bbbebf;
  display: block;
  margin-bottom: 12px;
  padding-left: 21px;
}

input {
  border: 1px solid #ebeef0;
  border-radius: 20px;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding: 0 21px;
}

.change-password {
  align-self: flex-end;
  margin-top: 36px;
  height: 41px;
  width: 151px;
  border: 1px solid #30a3ff;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 64px;
}

.book-header {
  display: flex;
  justify-content: space-between;
}

.book-header-left {
  display: flex;
}

.bhl-button {
  border: 0;
  appearance: none;
  height: 40px;
  width: 150px;
  border-radius: 20px;
  background-color: #1e1f20;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  svg {
    position: absolute;
    left: 20px;
    height: 16px;
    path {
      fill: white;
    }
  }
}

.book-header-button-play {
  background-color: #30a3ff;
  margin-right: 24px;
}

.book-header-right {
  display: flex;
}

.book-header-right .phr-dets {
  border: 1px solid #dee2e5;
  cursor: pointer;
  &:hover {
    border-color: #bbbebf;
  }
}

.book-major {
  margin-top: 36px;
  display: flex;
  align-items: flex-start;

  max-width: 728px;
  margin-left: auto;
  margin-right: auto;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 24px;
}

.book-major-cover {
  flex-shrink: 0;
  flex-basis: 210px;
  background-size: cover;
  border-radius: 20px;
  margin-right: 36px;
  overflow: hidden;
  img {
    max-width: 100%;
  }
}

.bm-rating {
  color: #ff6b56;
  font-size: 32px;
  font-weight: 900;
}

.bm-title {
  margin-top: 18px;
  margin-bottom: 18px;
}

.bm-author {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.bm-author-pic {
  background-size: cover;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 16px;
}

.bcr-bottom-left {
  margin-top: 36px;
  display: flex;
}

.book-description,
.recommended-books,
.browse-more-section,
.book-header {
  max-width: 100%;
}

.book-description {
  margin: 54px 0;
  max-width: 728px;
  flex-basis: 728px;
  h4 {
    font-size: 24px;
    font-weight: 400;
  }

  h3 {
    margin: 32px 0;
    font-size: 24px;
  }

  p {
    font-size: 18px;
    letter-spacing: -0.2px;
    line-height: 30px;
  }

  h2 {
    margin: 36px 0 24px;
    color: #bbbebf;
    font-size: 32px;
    font-weight: 900;
  }
}

.book-description-big {
  h1 {
    font-size: 38.4px;
  }
  h4 {
    font-size: 28.8px;
  }

  h3 {
    font-size: 28.8px;
  }

  p {
    font-size: 21.6px;
  }

  h2 {
    font-size: 38.4px;
  }
}

.bd-dots {
  padding: 0 0;
}

.recommended-books,
.browse-more-section {
  padding: 72px 0;
  border-top: 1px solid #e5e7ea;
}

.browse-more-section .for-you-header {
  display: flex;
  align-items: flex-start;
}

.browse-more-section .browse-more-scroller {
  position: relative;
  width: 100%;
}

.playlist-header {
  display: flex;
  justify-content: space-between;
}

.standard-button {
  height: 40px;
  width: 150px;
  border-radius: 20px;
  color: white;
  font-size: 12px;
  position: relative;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg,
  img {
    position: absolute;
    left: 20px;
  }
  img {
    width: auto;
    height: 9px;
  }
}

.standard-sub {
  font-size: 16px;
  color: #bbbebf;
}

.new-playlist {
  height: 40px;
  width: 150px;
  border-radius: 20px;
  background-color: #30a3ff;
  color: white;
  font-size: 12px;
  position: relative;
  text-decoration: none;
}

.new-playlist g {
  fill: white;
  color: white;
  stroke: white;
}

.new-playlist svg {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 14px;
}

.playlist-items {
  display: grid;
  grid-template-columns: repeat(6, calc(100% / 6));
  grid-gap: 36px 0;
}

.playlist-preview-box {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  position: relative;
  justify-self: center;
  &:nth-child(6n - 5) {
    justify-self: flex-start;
  }
  &:nth-child(6n - 6) {
    justify-self: flex-end;
  }
  &:hover {
    .playlist-preview {
      opacity: 0.5;
    }
    .pti-trash {
      display: flex;
    }
    .playlist-title-item svg {
      display: block;
    }
  }
}

.playlist-preview {
  width: 220px;
  height: 286px;
  border-radius: 20px;
  background-color: #f4f6f8;
  padding: 32px 24px;
  box-sizing: border-box;
  position: relative;
}

.pti-trash {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 1;
  display: none;
  background-color: #ffffff;
}

.playlist-title-item {
  text-align: center;
  margin-top: 16px;
}

.playlist-title-title {
  font-weight: 600;
  margin-bottom: 12px;
  position: relative;
  svg {
    position: absolute;
    right: 0;
    cursor: pointer;
    display: none;
    top: 0;
  }
}

input.playlist-title-title {
  height: 28px;
  text-align: center;
}

.playlist-subtitle {
  color: #bbbebf;
}

.preview-cover {
  background-size: 100% 100%;
}

.pp-four {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
  .preview-cover {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
  .preview-box-cover {
    padding: 6px;
    box-sizing: border-box;
    display: flex;
  }
}

.pp-two {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  .preview-cover {
    height: 50%;
    width: 100%;
    box-sizing: border-box;
  }
  .preview-box-cover {
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
}

.pp-one {
  display: grid;
  grid-template-columns: 100%;
  .preview-cover {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
  .preview-box-cover {
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
}

.real-playlist-header {
  display: flex;
}

.real-playlist-cover {
  height: 286px;
  width: 220px;
  border-radius: 20px;
  background-color: #f4f6f8;
  margin-right: 24px;
}

.playlist-input {
  border: 1px solid #dee2e5;
  border-radius: 0;
  font-size: 32px;
  font-weight: 900;
  padding: 12px 18px;
  height: unset;
  width: 800px;
  max-width: 100%;
}

.np-right-buttons {
  display: flex;
  margin-top: 24px;
}

.delete-np,
.create-np {
  height: 40px;
  width: 150px;
  position: relative;
  svg {
    path {
      fill: white;
    }
  }
}

.delete-np {
  background-color: #1e1f20;
  margin-right: 24px;
}

.create-np {
  background-color: #30a3ff;
}

.playlist-book-cards {
  margin-top: 72px;
}

.author-banner {
  height: 150px;
  width: 100%;
  border-radius: 20px;
  background-color: #f4f6f8;
  padding: 0 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.author-banner-right .np-right-buttons {
  margin: 0;
}

.author-banner-left {
  display: flex;
  align-items: center;
}

.abl-image {
  width: 72px;
  height: 72px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 24px;
}

.author-banner-left h1 {
  margin-bottom: 0;
}

.author-full-content {
  margin-top: 64px;
  #fyh-section {
    margin-bottom: 96px;
  }
}

.pop-books-scroller {
  display: flex;
  overflow: scroll;
  padding-bottom: 12px;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none;
}
.pop-books-scroller::-webkit-scrollbar {
  display: none; // Safari and Chrome
}

.pop-books-scroller .book-card-holder {
  min-width: calc(100% / 3);
  display: flex;
  flex: 1;
  &:nth-child(3n - 1) {
    justify-content: center;
  }
  &:nth-child(3n - 3) {
    justify-content: flex-end;
    margin-right: 50px;
  }
}

.det-item {
  position: relative;
  cursor: pointer;
  .bcm-flip {
    right: 60px;
  }
}
