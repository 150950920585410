@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

*,
*:after,
*:before {
  box-sizing: border-box; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

body {
  font-family: "Roboto", sans-serif;
  color: #1e1f20;
  -webkit-font-smoothing: antialiased; }

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  -ms-overflow-style: none !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
  font-family: "Roboto", sans-serif; }

h1 {
  font-size: 32px;
  margin-bottom: 40px; }

p {
  font-size: 16px; }

.container {
  max-width: 1080px;
  flex-basis: 1080px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */
.icon-arrowright {
  width: 0.599609375em; }

.icon-arrowup {
  width: 1.5556640625em; }

.icon-authors {
  width: 0.875em; }

.icon-browseleft {
  width: 1.142578125em; }

.icon-checkmark {
  width: 1.375em; }

.icon-checkw {
  width: 1.375em; }

.icon-delete {
  width: 0.8330078125em; }

.icon-details {
  width: 0.3330078125em; }

.icon-dots {
  width: 7em; }

.icon-downarrow {
  width: 1.6669921875em; }

.icon-longarrow {
  width: 1.400390625em; }

.icon-next {
  width: 1.099609375em; }

.icon-person {
  width: 0.875em; }

.icon-playw {
  width: 0.7998046875em; }

.icon-previous {
  width: 1.099609375em; }

.icon-rightarrow {
  width: 1.2998046875em; }

.icon-trash {
  width: 0.8330078125em; }

.icon-trashw {
  width: 0.8330078125em; }

.icon-wave {
  width: 9.06640625em; }

.header {
  display: flex;
  align-items: center;
  height: 100px;
  z-index: 334;
  position: relative;
  background-color: white; }

.header-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative; }

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
  width: 248px; }

.header-left img {
  height: 24px;
  position: fixed;
  top: 38px; }

.header-search {
  flex: 1 1;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 48px; }

.hs-close-icon {
  position: absolute;
  right: 72px;
  display: none; }

input[type="text"],
input[type="email"],
input[type="password"] {
  -webkit-appearance: none; }

.header-search-input {
  border: none;
  font-size: 16px;
  margin-left: 14px;
  flex: 1 1;
  padding-left: 12px;
  -webkit-appearance: none; }
  .header-search-input ::-webkit-input-placeholder {
    color: #bbbebf; }
  .header-search-input :-moz-placeholder {
    color: #bbbebf; }
  .header-search-input ::-moz-placeholder {
    color: #bbbebf; }
  .header-search-input :-ms-input-placeholder {
    color: #bbbebf; }

.search-results {
  position: absolute;
  top: 85px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.08);
  width: calc(100% - 48px);
  padding: 18px 24px;
  box-sizing: border-box;
  display: none; }

.header-search-active .hs-browse-icon {
  display: none; }

.header-search-active .header-search-input {
  margin-left: 0;
  padding-left: 0; }

.header-search-active .hs-close-icon {
  display: block;
  cursor: pointer; }

.header-search-active .search-results {
  display: block; }

.search-results-section {
  margin-bottom: 16px; }

.srs-title {
  color: #bbbebf; }

.srs-list {
  margin-top: 16px; }

.result {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #1e1f20;
  font-size: 16px;
  margin-bottom: 16px; }

.result svg {
  margin-right: 16px;
  width: 24px; }

.header-right {
  display: flex;
  height: 100%;
  align-items: center; }

.hs-browse-icon {
  position: absolute;
  left: 0; }

.header-and-picture {
  display: flex;
  align-items: center;
  cursor: pointer; }

.header-pic {
  width: 36px;
  height: 36px;
  background-size: cover; }

.name-and-drop {
  margin-left: 16px;
  position: relative;
  cursor: pointer; }

.header-name {
  font-size: 14px; }

.name-drop-arrow {
  margin-left: 21px;
  cursor: pointer; }

.nad-dropdown {
  position: absolute;
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.08);
  margin-top: 24px;
  width: 170px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  z-index: 2; }

.nad-dropdown a {
  display: flex;
  padding: 14px 20px;
  color: #1e1f20;
  text-decoration: none;
  font-size: 12px;
  align-items: center; }
  .nad-dropdown a:hover {
    font-weight: bold; }

.nad-dropdown a svg {
  margin-right: 16px; }

.nad-top {
  border-bottom: 1px solid #e5e7ea; }

.nad-middle {
  border-bottom: 1px solid #e5e7ea; }

.header-notifs {
  margin-left: 32px;
  cursor: pointer;
  width: 41px;
  height: 41px; }

.standard-circle-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  padding: 0; }
  .standard-circle-button:hover {
    border-color: #bbbebf; }

.play-button-circle,
.standard-circle-button-circle {
  border: 2px solid #e2e5e8; }

.white-play,
.white-pause {
  color: white; }

.white-pause {
  color: white; }

.my-arrows {
  display: flex;
  align-items: center; }

.my-arrows .standard-circle-button:first-of-type,
.bcr-bottom .standard-circle-button:first-of-type,
.bcr-bottom-right .standard-circle-button:first-of-type {
  margin-right: 8px; }

.active-play {
  width: unset;
  border-radius: 26px;
  background-color: #30a3ff;
  color: white;
  padding: 0 16px;
  border-color: #30a3ff !important; }
  .active-play span {
    display: block !important; }
  .active-play svg rect,
  .active-play svg use,
  .active-play svg path {
    fill: white;
    stroke: white; }
  .active-play svg {
    color: white;
    margin-right: 8px; }

.play-button {
  -webkit-transition: 200ms ease all;
  transition: 200ms ease all; }
  .play-button span {
    display: none; }
  @media (min-width: 768px) {
    .play-button:hover {
      width: unset;
      border-radius: 26px;
      background-color: #30a3ff;
      color: white;
      padding: 0 16px;
      border-color: #30a3ff !important; }
      .play-button:hover span {
        display: block !important; }
      .play-button:hover svg use,
      .play-button:hover svg path {
        fill: white;
        stroke: white; }
      .play-button:hover svg {
        color: white;
        margin-right: 8px; } }

.read-button {
  -webkit-transition: 200ms ease all;
  transition: 200ms ease all;
  text-decoration: none;
  margin-right: 0 !important; }
  .read-button span {
    display: none;
    text-decoration: none;
    font-size: 12px; }
  .read-button svg {
    width: 16px;
    height: 16px; }
  @media (min-width: 768px) {
    .read-button:hover {
      width: unset;
      border-radius: 26px;
      background-color: #1e1f20;
      color: white;
      padding: 0 16px;
      border-color: #1e1f20 !important; }
      .read-button:hover span {
        display: block;
        text-decoration: none; }
      .read-button:hover svg use,
      .read-button:hover svg path {
        fill: white;
        stroke: white; }
      .read-button:hover svg {
        color: white;
        margin-right: 8px; } }

.flip-svg svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.app-content {
  display: flex;
  min-height: 100vh;
  padding-top: 24px; }

.no-scroll {
  min-height: unset; }

.side-header {
  position: fixed;
  height: calc(100vh - 125px);
  -webkit-perspective: none !important;
          perspective: none !important;
  -webkit-perspective: initial !important;
          perspective: initial !important;
  -webkit-transition: none 0s ease 0s;
  transition: none 0s ease 0s;
  -webkit-transition: initial;
  transition: initial;
  -webkit-transform: none !important;
          transform: none !important;
  -webkit-transform: initial !important;
          transform: initial !important;
  max-width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.standard-page {
  flex: 1 1;
  padding-bottom: 64px;
  margin-left: 230px; }

.side-header-section {
  position: relative;
  margin-bottom: 24px; }

h4.shs-discover {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: #bbbebf;
  text-transform: uppercase; }

.shs-section-links a {
  display: flex;
  font-size: 14px;
  color: #1e1f20;
  text-decoration: none;
  height: 40px;
  margin-bottom: 10px;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer; }
  .shs-section-links a .shs-svg {
    font-size: 16px; }
  .shs-section-links a .shs-svg,
  .shs-section-links a .shs-svg path {
    fill: #1e1f20;
    stroke: #1e1f20; }
  .shs-section-links a:hover {
    background-color: #f4f6f8;
    color: #30a3ff !important; }
    .shs-section-links a:hover .shs-svg,
    .shs-section-links a:hover .shs-svg path {
      stroke: #30a3ff !important; }

.fill-svg:hover .shs-svg,
.fill-svg:hover .shs-svg path {
  fill: #30a3ff !important; }

.shs-svg {
  margin-right: 16px; }

.active-shs-link {
  background-color: #f4f6f8;
  color: #30a3ff !important; }
  .active-shs-link .shs-svg,
  .active-shs-link .shs-svg path {
    stroke: #30a3ff !important; }

.active-shs-link.fill-svg .shs-svg,
.active-shs-link.fill-svg .shs-svg path {
  fill: #30a3ff !important; }

.shs-section-links .no-fill-svg svg,
.shs-section-links .no-fill-svg path,
.shs-section-links .active-shs-link.no-fill-svg svg,
.shs-section-links .active-shs-link.no-fill-svg path {
  fill: unset !important; }

.playlist-details {
  display: none; }

.for-you-scroller {
  display: flex;
  overflow: scroll;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: absolute;
  width: calc(100% - 310px); }

.for-you-scroller::-webkit-scrollbar {
  display: none; }

#fyh-section {
  margin-bottom: 248px;
  overflow: visible; }

#fyh-scroller {
  padding-bottom: 250px;
  margin-bottom: -250px; }

.for-you-item {
  width: 350px;
  text-align: center;
  text-decoration: none; }

.browse-item p,
.for-you-item p {
  font-size: 20px;
  font-weight: 900;
  text-decoration: none;
  color: #212126;
  margin-top: 8px;
  line-height: 24px; }

.browse-item {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  flex-grow: 1;
  flex-shrink: 1; }
  .browse-item:hover .fyi-image,
  .browse-item:hover .browse-image {
    opacity: 0.8; }

.for-you-scroller .for-you-item:first-of-type {
  margin-left: 0; }

.fyi-image,
.browse-image {
  height: 177px;
  width: 100%;
  border-radius: 14px;
  background-size: cover;
  background-position: center;
  align-self: flex-start; }

.browse-item {
  margin-left: 0; }

.browse-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px; }

.standard-section {
  overflow: hidden;
  width: 100%;
  margin-bottom: 64px; }

.standard-page {
  overflow-x: hidden; }

.for-you-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.notifications {
  position: absolute;
  right: 0;
  top: 100px;
  display: flex;
  flex-direction: column;
  z-index: 222; }

.notification {
  background-color: #30a3ff;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-radius: 10px;
  margin-bottom: 24px;
  justify-content: space-between;
  width: 425px;
  max-width: 100%;
  box-sizing: border-box; }

.notif-content {
  font-size: 12px;
  font-weight: 500;
  padding: 24px 48px 24px 16px;
  line-height: 14px; }

.notif-content p {
  font-size: 12px; }

.notif-title {
  margin-bottom: 12px; }

.notif-ok {
  border-left: 1px solid rgba(255, 255, 255, 0.32);
  padding-left: 24px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer; }

.load-more {
  margin-top: 48px;
  text-align: center;
  padding: 12px 42px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #dee2e5;
  border-radius: 20px;
  will-change: opacity; }
  .load-more:hover {
    opacity: 0.8; }

.lm-holder {
  display: flex;
  justify-content: center; }

.support-button {
  width: 170px;
  background-color: #30a3ff;
  border-radius: 20px;
  position: relative;
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: 500;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 430px;
  position: absolute;
  bottom: 80px; }
  .support-button:hover {
    opacity: 0.8; }

.support-button svg {
  position: absolute;
  left: 14px; }

.player {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 420px;
  display: flex;
  flex-direction: column;
  z-index: 999; }
  .player:hover .player-hover-section {
    display: block; }

.player-preview {
  border-radius: 32px 32px 0 0;
  background-color: #1e1f20;
  padding: 24px 32px;
  box-sizing: border-box;
  color: white;
  z-index: 1; }

.pp-top {
  display: flex;
  align-items: center; }

.pp-active-title {
  font-size: 16px;
  font-weight: 500; }

.wave-holder {
  flex: 1 1;
  text-align: center; }

.pp-bottom {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.pp-time {
  font-size: 14px; }

.pp-progress {
  font-size: 12px;
  position: absolute;
  left: 5px;
  bottom: 5px; }

.pp-navs,
.chapter-left {
  display: flex;
  align-items: center; }
  .pp-navs [data-test-id="CircularProgressbarWithChildren"],
  .chapter-left [data-test-id="CircularProgressbarWithChildren"] {
    display: flex;
    align-items: center; }
    .pp-navs [data-test-id="CircularProgressbarWithChildren"] > div,
    .chapter-left [data-test-id="CircularProgressbarWithChildren"] > div {
      display: flex; }
  .pp-navs .CircularProgressbar,
  .chapter-left .CircularProgressbar {
    box-sizing: content-box;
    padding: 0 40px;
    color: white;
    height: 41px;
    width: 41px; }
  .pp-navs .CircularProgressbar-trail,
  .pp-navs .CircularProgressbar-path,
  .chapter-left .CircularProgressbar-trail,
  .chapter-left .CircularProgressbar-path {
    stroke-width: 4; }
  .pp-navs [data-test-id="CircularProgressbarWithChildren__children"],
  .chapter-left [data-test-id="CircularProgressbarWithChildren__children"] {
    margin-top: unset !important;
    top: 0; }
    .pp-navs [data-test-id="CircularProgressbarWithChildren__children"] svg,
    .chapter-left [data-test-id="CircularProgressbarWithChildren__children"] svg {
      cursor: pointer; }

.pp-nav-play {
  width: 11px;
  cursor: pointer; }

.player-hover-section {
  background-color: #f4f6f8;
  padding: 24px 32px 48px;
  position: relative;
  top: 24px;
  border-radius: 32px 32px 0 0;
  display: none; }

.player-mobile-hover-section {
  background-color: #f4f6f8;
  padding: 24px 32px 48px;
  position: relative;
  top: 24px;
  border-radius: 32px 32px 0 0;
  display: none; }

.player-mobile-hover-section .arrowup {
  padding: 15.5px 13px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(180deg) !important;
          transform: translateX(-50%) rotate(180deg) !important; }

.mpb-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px; }

.arrowup {
  margin: 0 auto;
  display: block;
  cursor: pointer; }

.phs-content {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.phs-cover-details {
  display: flex;
  align-items: center; }

.phs-cover {
  height: 68px;
  width: 45px;
  background-size: cover;
  border-radius: 3px; }

.phs-details {
  margin-left: 16px; }

.phs-title {
  font-weight: bold;
  margin-bottom: 9px; }

.phs-author {
  color: #d8d8d8; }

.player-open-section {
  background-color: #1e1f20;
  color: white;
  padding: 0 32px;
  max-height: 0;
  -webkit-transition: 500ms ease all;
  transition: 500ms ease all; }

.pos-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3); }

.chapter-list {
  max-height: 0;
  margin-top: 0;
  -webkit-transition: 500ms ease all;
  transition: 500ms ease all; }

.player-open .player-hover-section {
  display: block; }

.player-open .player-open-section {
  max-height: 50vh;
  padding: 0 32px 24px;
  overflow-y: scroll; }
  .player-open .player-open-section::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #1e1f20; }
  .player-open .player-open-section::-webkit-scrollbar {
    width: 12px;
    background-color: #1e1f20; }
  .player-open .player-open-section::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555; }

.player-open .chapter-list {
  margin-top: 36px;
  max-height: 640px; }

.player-open .arrowup {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.chapter {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.chapter-left {
  width: 60px;
  justify-content: flex-start; }

.chapter-name {
  flex: 1 1;
  cursor: pointer; }

.chapter-left .CircularProgressbar {
  padding: 0; }

.chapter-length {
  opacity: 0.4; }

.active-chapter .chapter-name {
  color: #30a3ff; }

.active-chapter .chapter-length {
  opacity: 1; }

.notifications-section {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  height: 100vh;
  box-sizing: border-box;
  display: none; }

.notifications-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #777777;
  opacity: 0.7; }

.notifications-tab {
  position: absolute;
  right: 0;
  width: 450px;
  background-color: white;
  height: 100vh;
  padding: 120px 16px 24px;
  box-sizing: border-box;
  font-size: 20px;
  overflow: scroll; }

.show-notification-tab .notifications-section {
  display: block; }

.notifications-tab .notif-title {
  padding-left: 16px; }

.notification-item {
  display: flex;
  background-color: #f4f6f8;
  border-radius: 8px;
  margin-bottom: 12px;
  align-items: center; }

.nitem-left {
  font-size: 16px;
  padding: 20px 24px;
  flex: 1 1; }

.nitem-title {
  font-weight: 600;
  flex: 1 1; }

.nitem-time {
  font-size: 14px;
  color: #bbbebf;
  margin-top: 12px; }

.nitem-title a {
  color: #30a3ff;
  text-decoration: none; }

.nitem-cover {
  height: 75px;
  width: 50px;
  background-size: cover;
  margin-right: 5px;
  border-radius: 3px; }

.notification-update {
  display: flex;
  border: 1px solid #ebeef0;
  box-sizing: border-box;
  margin-bottom: 12px;
  align-items: center;
  border-radius: 8px; }
  .notification-update svg {
    padding-left: 16px; }

.nitem-link {
  padding-right: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #30a3ff;
  text-decoration: none; }

.notif-items {
  margin-top: 36px; }

.flip {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.book-card-menu-mobile-holder {
  display: none; }

.mobile-buttons {
  width: 170px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  height: calc(100vh - 235px);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.mobile-menu-logo {
  font-size: 3em; }

.instagram-btn {
  position: relative;
  margin-bottom: 12px;
  width: 170px;
  height: 40px;
  background: -webkit-linear-gradient(45deg, #FFC926 9.15%, #FF5346 49%, #C536DE 100%);
  background: linear-gradient(45deg, #FFC926 9.15%, #FF5346 49%, #C536DE 100%);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-left: 18px;
  padding-right: 12px;
  justify-content: space-between;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease; }
  .instagram-btn:focus {
    outline: none; }
  .instagram-btn .wrapper {
    text-align: center;
    position: absolute;
    z-index: 0;
    background-color: #fff;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-left: 20px;
    border-radius: 25px;
    left: 1px;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease; }
  .instagram-btn .instagram-btn-icon {
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease; }
    .instagram-btn .instagram-btn-icon .icon-wrap {
      -webkit-transform: translateY(-1px);
              transform: translateY(-1px);
      position: relative;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .instagram-btn .instagram-btn-icon .icon-wrap svg {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: 0.3s all ease;
        transition: 0.3s all ease; }
        .instagram-btn .instagram-btn-icon .icon-wrap svg * {
          opacity: 1;
          -webkit-transition: 0.3s all ease;
          transition: 0.3s all ease; }
        .instagram-btn .instagram-btn-icon .icon-wrap svg:nth-child(1) {
          opacity: 1; }
        .instagram-btn .instagram-btn-icon .icon-wrap svg:nth-child(2) {
          opacity: 0; }
  .instagram-btn .arrow {
    z-index: 1; }
  .instagram-btn .label {
    z-index: 0;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #1E1F20;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease; }
  .instagram-btn:focus .wrapper, .instagram-btn:hover .wrapper {
    background-color: transparent; }
  .instagram-btn:focus .label, .instagram-btn:hover .label {
    color: #fff; }
  .instagram-btn:focus .instagram-btn-icon svg:nth-child(1), .instagram-btn:hover .instagram-btn-icon svg:nth-child(1) {
    opacity: 0; }
  .instagram-btn:focus .instagram-btn-icon svg:nth-child(2), .instagram-btn:hover .instagram-btn-icon svg:nth-child(2) {
    opacity: 1; }

.socials-btn {
  align-items: center; }
  .socials-btn a {
    text-decoration: none;
    margin-bottom: 12px;
    padding-left: 18px;
    padding-right: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #DEE2E5;
    border-radius: 24px;
    width: 170px;
    height: 40px;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease; }
    .socials-btn a:first-child {
      margin-right: 24px; }
    .socials-btn a .socials-btn-icon {
      display: flex;
      align-items: center; }
    .socials-btn a .label {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #1E1F20; }
    .socials-btn a .arrow {
      display: flex;
      align-items: center; }
    .socials-btn a:focus {
      outline: none; }
    .socials-btn a:hover, .socials-btn a:focus {
      border-color: rgba(222, 226, 229, 0.4);
      background-color: rgba(222, 226, 229, 0.4); }

.book-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px; }

.book-card {
  display: flex;
  flex: 1 1;
  position: relative;
  -webkit-transition: 200ms ease opacity;
  transition: 200ms ease opacity;
  will-change: opacity;
  text-decoration: none;
  color: inherit; }
  .book-card:hover .book-card-cover {
    opacity: 0.7; }

.book-card-cover {
  width: 135px;
  flex-shrink: 0;
  overflow: hidden; }
  .book-card-cover img {
    border-radius: 14px;
    max-width: 100%; }

.bc-right {
  box-sizing: border-box;
  padding: 16px 0 16px 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media screen and (max-width: 420px) {
    .bc-right {
      flex-direction: column !important; } }

.bcr-rating {
  font-size: 24px;
  font-weight: bold;
  color: #ff6b56;
  margin-bottom: 2px;
  display: block; }

.bcr-title {
  font-size: 16px;
  color: #212126;
  margin-bottom: 4px;
  text-decoration: none;
  font-weight: 700; }

.bcr-title-link {
  text-decoration: none; }

.bcr-author {
  font-size: 16px;
  margin-bottom: 4px;
  color: #bbbebf; }

.bcr-bottom {
  display: flex; }
  .bcr-bottom img {
    margin-right: 16px;
    cursor: pointer; }
    .bcr-bottom img:last-child {
      margin-right: 0; }

.details-svg {
  position: absolute;
  right: -13px;
  top: 0;
  cursor: pointer;
  width: 12px; }

.book-card-menu,
.author-menu {
  position: absolute;
  right: -15px;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  background-color: white;
  border-radius: 8px;
  width: 250px;
  z-index: 1;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; }

.book-card-menu-top a,
.bcmt-content,
.bcm-share-link {
  padding: 12px 24px;
  color: #1e1f20;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative; }
  .book-card-menu-top a:hover,
  .bcmt-content:hover,
  .bcm-share-link:hover {
    font-weight: bold; }
    .book-card-menu-top a:hover > div,
    .bcmt-content:hover > div,
    .bcm-share-link:hover > div {
      font-weight: normal; }

.bcm-share-link {
  padding: 24px; }

.book-card-menu-top {
  padding: 12px 0;
  border-bottom: 1px solid #e5e7ea; }

.playlist-dropdown,
.share-dropdown {
  position: absolute;
  right: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  top: -2px;
  width: 250px;
  border-radius: 0 8px 8px 8px;
  display: none; }

.share-dropdown {
  padding: 12px 0; }
  .share-dropdown a,
  .share-dropdown .shd-bottom span {
    display: flex;
    padding: 16px 16px;
    align-items: center;
    text-decoration: none;
    color: inherit; }
    .share-dropdown a:hover,
    .share-dropdown .shd-bottom span:hover {
      font-weight: bold; }
    .share-dropdown a svg,
    .share-dropdown .shd-bottom span svg {
      margin-right: 12px; }

.plist-dropdown-bottom,
.shd-bottom {
  border-top: 1px solid #e5e7ea;
  padding: 12px 0; }

.shd-bottom {
  padding-bottom: 0; }

.pd-newlist svg,
.pd-item svg {
  margin-right: 12px; }

.pd-item {
  justify-content: flex-start;
  padding: 16px 16px;
  display: flex;
  align-items: center; }
  .pd-item span:hover {
    font-weight: bold; }

.playlist-hover:hover .playlist-dropdown {
  display: block; }

.bcm-share-link:hover .share-dropdown {
  display: block; }

.pd-newlist {
  padding: 18px 16px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start !important; }
  .pd-newlist a,
  .pd-newlist span {
    padding: 0; }

.bcm-flip {
  right: 15px;
  -webkit-transform: unset;
          transform: unset; }
  .bcm-flip .book-card-menu-top a,
  .bcm-flip .bcmt-content,
  .bcm-flip .bcm-share-link {
    flex-direction: row-reverse; }
  .bcm-flip .arrow-right {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .bcm-flip .playlist-dropdown,
  .bcm-flip .share-dropdown {
    right: unset;
    left: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  .bcm-flip a.pd-newlist {
    flex-direction: row; }

.author-details {
  flex: 1 1;
  padding: 24px 0 24px 64px; }

.author-details-header {
  display: flex;
  justify-content: space-between; }

.adh-name-books h1 {
  text-transform: capitalize;
  margin-bottom: 20px; }

.adh-num-books {
  color: #bbbebf; }

.adh-view-all {
  font-size: 12px;
  height: 40px;
  width: 150px;
  border: 1px solid #dee2e5;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: inherit; }

.adh-view-all svg {
  position: absolute;
  right: 14px;
  width: 12px;
  height: 9px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.author-book-cards {
  margin-top: 40px; }

.bcr-bottom-left {
  display: flex;
  flex-wrap: wrap; }

.category-label {
  border-radius: 20px;
  padding: 14px 21px;
  font-size: 12px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px; }
  .category-label:hover {
    opacity: 0.8; }

.bcr-bottom-left .category-label:nth-child(1) {
  background-color: #f0eaff; }

.bcr-bottom-left .category-label:nth-child(2) {
  background-color: #ffeaf6; }

.bcr-bottom-left .category-label:nth-child(3) {
  background-color: #eafffa; }

.bcr-bottom-left .category-label:nth-child(4) {
  background-color: antiquewhite; }

.bcr-bottom-left .category-label:last-of-type {
  margin-right: 0; }

.book-card-holder-long {
  margin-bottom: 30px; }
  .book-card-holder-long .bc-right {
    flex: 1 1; }
  .book-card-holder-long .bcr-bottom {
    justify-content: space-between; }
  .book-card-holder-long .details-svg {
    position: relative;
    top: unset;
    right: unset;
    margin-left: 30px; }
  .book-card-holder-long .bcr-bottom-right {
    display: flex;
    align-items: center; }
  .book-card-holder-long .bcm-flip {
    right: 50px;
    bottom: 25px; }

.open-nav {
  z-index: 3; }

.real-playlist-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  box-sizing: border-box; }

.real-playlist-cover img {
  max-width: 100%;
  max-height: 100%; }

.book-page .browse-more-section {
  margin-bottom: 0 !important; }

.book-page {
  overflow-y: hidden; }
  .book-page .phr-dets {
    position: relative; }
  .book-page .bcm-flip {
    right: 0;
    top: 48px; }

.standard-page.book-page .book-description:last-child {
  padding: 0; }

.standard-page.book-page .book-description {
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px; }
  .standard-page.book-page .book-description h3 {
    font-weight: 300;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
    line-height: 35px;
    color: #172B61; }
  .standard-page.book-page .book-description .enumeration {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #172B61; }
  .standard-page.book-page .book-description .enumeration span {
    margin-bottom: 15px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-family: serif;
    font-family: initial; }
  .standard-page.book-page .book-description .enumeration p {
    margin-bottom: 10px; }
  .standard-page.book-page .book-description .separator svg {
    margin: 15px auto 28px auto;
    width: 100%;
    text-align: center; }
  .standard-page.book-page .book-description p.paragraph {
    color: #404040;
    margin-bottom: 0; }
    .standard-page.book-page .book-description p.paragraph b {
      font-weight: bold; }
    .standard-page.book-page .book-description p.paragraph i {
      font-style: italic; }
  .standard-page.book-page .book-description p.legal-note {
    background-color: #f4f4f4;
    color: #b7b5b5;
    margin-top: 15px;
    padding: 12px;
    text-align: justify;
    line-height: 25px;
    font-style: italic; }

.category-splash {
  width: 100%;
  height: 177px;
  border-radius: 24px;
  margin-bottom: 64px;
  background-size: cover; }

.category-section-header {
  display: flex;
  justify-content: space-between; }

.sort-toggle {
  display: flex;
  height: 40px;
  border: 1px solid #ebeef0;
  border-radius: 20px; }

.sort-toggle div {
  width: 108px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 20px;
  cursor: pointer; }
  .sort-toggle div:hover {
    opacity: 0.5; }

.active-sort-toggle {
  background-color: #1e1f20;
  color: white;
  opacity: 1 !important; }

.author-page {
  display: flex; }

.author-left {
  width: 337px;
  border-radius: 24px 24px 0 0;
  background-color: #f4f6f8;
  padding: 24px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: calc(90% - 180px); }

.author-left::-webkit-scrollbar {
  display: none; }

.author-items {
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none; }

.author-items::-webkit-scrollbar {
  display: none; }

.author-item {
  display: flex;
  text-decoration: none;
  margin-bottom: 24px;
  align-items: center;
  color: #1e1f20;
  position: relative; }
  .author-item:hover {
    font-weight: bold; }
    .author-item:hover .author-menu {
      font-weight: normal;
      font-weight: initial; }

.author-item a {
  flex: 1 1;
  text-decoration: none;
  color: #1e1f20;
  display: flex;
  align-items: center; }

.author-pic {
  height: 40px;
  width: 40px;
  margin-right: 12px;
  border-radius: 50%; }

.author-menu {
  top: 0; }

.author-details-link {
  cursor: pointer;
  padding: 14px 18px;
  position: relative;
  right: -20px; }

.author-book-cards {
  display: flex;
  flex-direction: column; }

.profile-header {
  height: 148px;
  width: 100%;
  background-color: #f4f6f8;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 54px 40px;
  box-sizing: border-box; }

.profile-header-left {
  display: flex;
  align-items: center; }

.p-profile-picture {
  background-size: cover;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 24px; }

.p-profile-picture svg {
  display: none; }

.profile-header-left h1 {
  margin: 0; }

.p-profile-picture:hover svg {
  display: block; }

.p-profile-picture:hover {
  opacity: 0.7; }

.profile-header-right {
  display: flex;
  align-items: center; }

.standard-button.logout-button {
  height: 40px;
  width: 170px;
  border-radius: 20px;
  background-color: #1e1f20;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px !important; }

.logout-button svg {
  position: absolute;
  left: 20px; }

.phr-dets,
.cat-dets {
  margin-left: 24px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%; }

.cat-dets {
  border: 1px solid #dee2e5; }

.csh-right {
  display: flex; }

.profile-change {
  margin-top: 64px;
  padding: 0 64px; }

.profile-change-section {
  margin-bottom: 64px;
  display: flex;
  flex-direction: column; }

.profile-change-section h2 {
  font-size: 20px; }

.h-form {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap; }

.h-form-group {
  width: 29.5%;
  margin-right: 1%; }

label {
  font-size: 14px;
  color: #bbbebf;
  display: block;
  margin-bottom: 12px;
  padding-left: 21px; }

input {
  border: 1px solid #ebeef0;
  border-radius: 20px;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding: 0 21px; }

.change-password {
  align-self: flex-end;
  margin-top: 36px;
  height: 41px;
  width: 151px;
  border: 1px solid #30a3ff;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 64px; }

.book-header {
  display: flex;
  justify-content: space-between; }

.book-header-left {
  display: flex; }

.bhl-button {
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  height: 40px;
  width: 150px;
  border-radius: 20px;
  background-color: #1e1f20;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer; }
  .bhl-button svg {
    position: absolute;
    left: 20px;
    height: 16px; }
    .bhl-button svg path {
      fill: white; }

.book-header-button-play {
  background-color: #30a3ff;
  margin-right: 24px; }

.book-header-right {
  display: flex; }

.book-header-right .phr-dets {
  border: 1px solid #dee2e5;
  cursor: pointer; }
  .book-header-right .phr-dets:hover {
    border-color: #bbbebf; }

.book-major {
  margin-top: 36px;
  display: flex;
  align-items: flex-start;
  max-width: 728px;
  margin-left: auto;
  margin-right: auto; }

.mb-2 {
  margin-bottom: 8px; }

.mb-4 {
  margin-bottom: 24px; }

.book-major-cover {
  flex-shrink: 0;
  flex-basis: 210px;
  background-size: cover;
  border-radius: 20px;
  margin-right: 36px;
  overflow: hidden; }
  .book-major-cover img {
    max-width: 100%; }

.bm-rating {
  color: #ff6b56;
  font-size: 32px;
  font-weight: 900; }

.bm-title {
  margin-top: 18px;
  margin-bottom: 18px; }

.bm-author {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }

.bm-author-pic {
  background-size: cover;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 16px; }

.bcr-bottom-left {
  margin-top: 36px;
  display: flex; }

.book-description,
.recommended-books,
.browse-more-section,
.book-header {
  max-width: 100%; }

.book-description {
  margin: 54px 0;
  max-width: 728px;
  flex-basis: 728px; }
  .book-description h4 {
    font-size: 24px;
    font-weight: 400; }
  .book-description h3 {
    margin: 32px 0;
    font-size: 24px; }
  .book-description p {
    font-size: 18px;
    letter-spacing: -0.2px;
    line-height: 30px; }
  .book-description h2 {
    margin: 36px 0 24px;
    color: #bbbebf;
    font-size: 32px;
    font-weight: 900; }

.book-description-big h1 {
  font-size: 38.4px; }

.book-description-big h4 {
  font-size: 28.8px; }

.book-description-big h3 {
  font-size: 28.8px; }

.book-description-big p {
  font-size: 21.6px; }

.book-description-big h2 {
  font-size: 38.4px; }

.bd-dots {
  padding: 0 0; }

.recommended-books,
.browse-more-section {
  padding: 72px 0;
  border-top: 1px solid #e5e7ea; }

.browse-more-section .for-you-header {
  display: flex;
  align-items: flex-start; }

.browse-more-section .browse-more-scroller {
  position: relative;
  width: 100%; }

.playlist-header {
  display: flex;
  justify-content: space-between; }

.standard-button {
  height: 40px;
  width: 150px;
  border-radius: 20px;
  color: white;
  font-size: 12px;
  position: relative;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .standard-button svg,
  .standard-button img {
    position: absolute;
    left: 20px; }
  .standard-button img {
    width: auto;
    height: 9px; }

.standard-sub {
  font-size: 16px;
  color: #bbbebf; }

.new-playlist {
  height: 40px;
  width: 150px;
  border-radius: 20px;
  background-color: #30a3ff;
  color: white;
  font-size: 12px;
  position: relative;
  text-decoration: none; }

.new-playlist g {
  fill: white;
  color: white;
  stroke: white; }

.new-playlist svg {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 14px; }

.playlist-items {
  display: grid;
  grid-template-columns: repeat(6, calc(100% / 6));
  grid-gap: 36px 0; }

.playlist-preview-box {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  position: relative;
  justify-self: center; }
  .playlist-preview-box:nth-child(6n - 5) {
    justify-self: flex-start; }
  .playlist-preview-box:nth-child(6n - 6) {
    justify-self: flex-end; }
  .playlist-preview-box:hover .playlist-preview {
    opacity: 0.5; }
  .playlist-preview-box:hover .pti-trash {
    display: flex; }
  .playlist-preview-box:hover .playlist-title-item svg {
    display: block; }

.playlist-preview {
  width: 220px;
  height: 286px;
  border-radius: 20px;
  background-color: #f4f6f8;
  padding: 32px 24px;
  box-sizing: border-box;
  position: relative; }

.pti-trash {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 1;
  display: none;
  background-color: #ffffff; }

.playlist-title-item {
  text-align: center;
  margin-top: 16px; }

.playlist-title-title {
  font-weight: 600;
  margin-bottom: 12px;
  position: relative; }
  .playlist-title-title svg {
    position: absolute;
    right: 0;
    cursor: pointer;
    display: none;
    top: 0; }

input.playlist-title-title {
  height: 28px;
  text-align: center; }

.playlist-subtitle {
  color: #bbbebf; }

.preview-cover {
  background-size: 100% 100%; }

.pp-four {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%); }
  .pp-four .preview-cover {
    height: 100%;
    width: 100%;
    box-sizing: border-box; }
  .pp-four .preview-box-cover {
    padding: 6px;
    box-sizing: border-box;
    display: flex; }

.pp-two {
  display: grid;
  grid-template-columns: repeat(2, 50%); }
  .pp-two .preview-cover {
    height: 50%;
    width: 100%;
    box-sizing: border-box; }
  .pp-two .preview-box-cover {
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center; }

.pp-one {
  display: grid;
  grid-template-columns: 100%; }
  .pp-one .preview-cover {
    height: 100%;
    width: 100%;
    box-sizing: border-box; }
  .pp-one .preview-box-cover {
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center; }

.real-playlist-header {
  display: flex; }

.real-playlist-cover {
  height: 286px;
  width: 220px;
  border-radius: 20px;
  background-color: #f4f6f8;
  margin-right: 24px; }

.playlist-input {
  border: 1px solid #dee2e5;
  border-radius: 0;
  font-size: 32px;
  font-weight: 900;
  padding: 12px 18px;
  height: unset;
  width: 800px;
  max-width: 100%; }

.np-right-buttons {
  display: flex;
  margin-top: 24px; }

.delete-np,
.create-np {
  height: 40px;
  width: 150px;
  position: relative; }
  .delete-np svg path,
  .create-np svg path {
    fill: white; }

.delete-np {
  background-color: #1e1f20;
  margin-right: 24px; }

.create-np {
  background-color: #30a3ff; }

.playlist-book-cards {
  margin-top: 72px; }

.author-banner {
  height: 150px;
  width: 100%;
  border-radius: 20px;
  background-color: #f4f6f8;
  padding: 0 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box; }

.author-banner-right .np-right-buttons {
  margin: 0; }

.author-banner-left {
  display: flex;
  align-items: center; }

.abl-image {
  width: 72px;
  height: 72px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 24px; }

.author-banner-left h1 {
  margin-bottom: 0; }

.author-full-content {
  margin-top: 64px; }
  .author-full-content #fyh-section {
    margin-bottom: 96px; }

.pop-books-scroller {
  display: flex;
  overflow: scroll;
  padding-bottom: 12px;
  -ms-overflow-style: none;
  scrollbar-width: none; }

.pop-books-scroller::-webkit-scrollbar {
  display: none; }

.pop-books-scroller .book-card-holder {
  min-width: calc(100% / 3);
  display: flex;
  flex: 1 1; }
  .pop-books-scroller .book-card-holder:nth-child(3n - 1) {
    justify-content: center; }
  .pop-books-scroller .book-card-holder:nth-child(3n - 3) {
    justify-content: flex-end;
    margin-right: 50px; }

.det-item {
  position: relative;
  cursor: pointer; }
  .det-item .bcm-flip {
    right: 60px; }

.mobile-menu-section {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 56px);
  background-color: white;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;
  padding: 36px;
  display: none;
  overflow: scroll; }

.mobile-menu-home-header {
  text-align: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #e5e7ea; }

.mobile-menu-home-header img {
  width: 72px;
  height: 72px;
  display: block;
  margin: 0 auto 12px; }

.mobile-menu-home-header span {
  font-size: 18px;
  font-weight: bold; }

.mmh-links {
  margin-top: 36px;
  text-align: center;
  display: flex;
  flex-direction: column; }

.mmh-links a,
.mmh-links span {
  display: block;
  color: inherit;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 21px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer; }

.notif-number {
  margin-bottom: 0 !important;
  position: absolute !important;
  margin-left: 70px;
  background-color: #ff6b56;
  color: white !important;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-size: 14px !important;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.mobile-menu-section .logout-button {
  margin: 12px auto 0;
  background-color: #e5e7ea;
  color: inherit; }

.mobile-menu-section .support-button {
  position: absolute;
  margin: 0;
  bottom: 150px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.mobile-settings-header {
  display: flex;
  justify-content: space-between; }

.mobile-settings-header .phr-dets {
  margin: 0;
  border: 1px solid #e5e7ea;
  cursor: pointer; }

.mms-big-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid #e5e7ea; }

.mms-pic {
  width: 100%;
  height: 100%;
  background-size: cover; }

.mms-big-pic {
  position: relative;
  cursor: pointer; }

.mms-big-pic svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  display: none; }

.mms-big-pic:hover {
  opacity: 0.5; }
  .mms-big-pic:hover svg {
    display: block; }

.mobile-settings-options {
  margin: 36px 0;
  display: flex; }

.mobile-settings-options span {
  font-size: 12px;
  font-weight: 600;
  margin-right: 36px;
  color: #d8d8d8;
  cursor: pointer; }

.mso-active {
  color: #1e1f20 !important; }

.save-changes {
  background-color: #30a3ff;
  margin-top: 24px; }

.delete-i {
  color: #ff6b56;
  font-size: 14px;
  cursor: pointer; }
  .delete-i svg path {
    fill: #ff6b56; }

.delete-sure {
  padding: 36px 24px; }

.delete-sure h2 {
  font-size: 24px;
  line-height: 1.2; }

.delete-bar {
  margin-top: 28px;
  height: 64px;
  border: 1px solid #e5e7ea;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600; }

.delete-bar img {
  margin-right: 16px; }

.delete-buttons {
  display: flex;
  margin-top: 32px;
  justify-content: space-between; }

.delete-buttons .standard-button:first-of-type {
  background-color: #e5e7ea;
  color: inherit; }

.delete-buttons .standard-button:last-of-type {
  background-color: #ff6b56; }

.mn-header {
  align-items: center; }

.notif-title {
  font-size: 21px; }

.mobile-menu-section .notifications-tab {
  position: relative;
  height: unset;
  right: unset;
  padding: 0;
  max-width: 100%; }

.mobile-bottom-header {
  display: none; }

.book-header-mobile-links {
  display: none; }

@media (max-width: 1820px) {
  .browse-item {
    max-width: 100%; }
  .fyi-image,
  .browse-image {
    height: 160px;
    border-radius: 64px; }
  .category-splash {
    height: 160px; }
  .player {
    width: 340px; }
  .pp-active-title {
    font-size: 14px; }
  p {
    font-size: 14px; }
  .playlist-items {
    display: grid;
    grid-template-columns: repeat(4, calc(100% / 4));
    grid-gap: 36px 0; }
  .playlist-preview-box {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    position: relative;
    justify-self: center; }
    .playlist-preview-box:nth-child(6n - 5) {
      justify-self: center; }
    .playlist-preview-box:nth-child(6n - 6) {
      justify-self: center; }
    .playlist-preview-box:nth-child(4n - 3) {
      justify-self: flex-start; }
    .playlist-preview-box:nth-child(4n - 4) {
      justify-self: flex-end; }
    .playlist-preview-box:hover .playlist-preview {
      opacity: 0.5; }
    .playlist-preview-box:hover .pti-trash {
      display: flex; } }

@media (max-width: 1440px) {
  .fyi-image,
  .browse-image {
    height: 129px;
    border-radius: 14px; }
  .category-splash {
    height: 126px; }
  .author-page {
    flex-direction: column; }
  .author-left {
    width: 100%;
    border-radius: 24px 0 0 24px;
    padding-right: 0;
    position: absolute;
    max-width: calc(90% - 180px); }
  .author-details {
    margin-top: 144px; }
  .author-items {
    flex-direction: row;
    overflow: scroll; }
    .author-items .author-item:last-of-type {
      padding-right: 40px; }
  .author-item {
    flex-direction: column;
    margin-right: 18px;
    min-width: 80px;
    text-align: center;
    margin-bottom: 0; }
  .author-pic {
    margin-bottom: 20px;
    margin-right: 0; }
  .author-item a {
    display: flex;
    flex-direction: column; }
  .author-details-link {
    display: none; }
  .ai-name {
    line-height: 1.35; }
  .author-details {
    padding: 48px 0; }
  .bcr-bottom-left {
    margin-top: 0; }
  .bcr-bottom img {
    margin-right: 8px; }
  .book-card-holder-long .details-svg {
    margin-left: 16px; }
  .np-right-buttons {
    display: none; }
  .pop-books-scroller .book-card-holder {
    margin-right: 48px; }
  .profile-change {
    padding: 0 16px; }
  .np-right {
    flex: 1 1; }
  .playlist-input {
    width: unset; }
  .np-right .np-right-buttons {
    display: flex; }
  .pop-books-scroller .book-card-holder {
    min-width: 50%;
    display: flex;
    flex: 1 1;
    margin-right: 0; }
    .pop-books-scroller .book-card-holder:nth-child(3n - 1) {
      justify-content: flex-start; }
    .pop-books-scroller .book-card-holder:nth-child(3n - 3) {
      justify-content: flex-start;
      margin-right: 0; }
    .pop-books-scroller .book-card-holder:nth-child(2n - 2) {
      justify-content: flex-start;
      margin-right: 50px; }
  .playlist-items {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-gap: 36px 0; }
  .playlist-preview-box {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    position: relative;
    justify-self: center; }
    .playlist-preview-box:nth-child(4n - 3) {
      justify-self: center; }
    .playlist-preview-box:nth-child(4n - 4) {
      justify-self: center; }
    .playlist-preview-box:nth-child(3n - 2) {
      justify-self: flex-start; }
    .playlist-preview-box:nth-child(3n - 3) {
      justify-self: flex-end; }
    .playlist-preview-box:hover .playlist-preview {
      opacity: 0.5; }
    .playlist-preview-box:hover .pti-trash {
      display: flex; } }

@media (max-width: 1024px) {
  .side-header {
    width: 190px; }
  .header-left {
    width: 190px; }
  .header-search-input {
    font-size: 14px; }
  .name-and-drop {
    margin-left: 8px; }
  .name-drop-arrow {
    margin-left: 7px; }
  .header-notifs {
    margin-left: 16px; }
  .bcr-rating {
    font-size: 20px; }
  .bcr-title {
    font-size: 16px; }
  .bc-right {
    padding: 16px; }
  .bcr-bottom img {
    margin-right: 8px;
    width: 27px; }
  .bcr-author {
    font-size: 14px; }
  .details-svg {
    top: 0;
    padding: 14px; }
  .category-label {
    font-size: 10px;
    padding: 7px 14px; }
  .pop-books-scroller .bcr-top {
    flex: 1 1; }
  .h-form-group {
    width: 300px;
    margin-bottom: 12px;
    margin-right: 1%; }
  .profile-header-left h1 {
    font-size: 21px; }
  .p-profile-picture {
    min-width: 72px; }
  .np-right {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
  .np-right-buttons {
    flex-direction: column; }
  .delete-np {
    margin-bottom: 24px; }
  .playlist-input {
    width: 100%; }
  .playlist-items {
    display: grid;
    grid-template-columns: repeat(2, calc(100% / 2));
    grid-gap: 36px 0; }
  .playlist-preview-box {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    position: relative;
    justify-self: center; }
    .playlist-preview-box:nth-child(3n - 2) {
      justify-self: center; }
    .playlist-preview-box:nth-child(3n - 3) {
      justify-self: center; }
    .playlist-preview-box:nth-child(2n - 1) {
      justify-self: flex-start; }
    .playlist-preview-box:nth-child(2n - 2) {
      justify-self: flex-end; }
    .playlist-preview-box:hover .playlist-preview {
      opacity: 0.5; }
    .playlist-preview-box:hover .pti-trash {
      display: flex; } }

@media (max-width: 768px) {
  .container {
    padding-left: 12px;
    padding-right: 12px; }
  .mobile-menu-section {
    display: block; }
  .header-right {
    display: none; }
  .header-search {
    width: 95px;
    flex: unset;
    padding-right: 0;
    height: unset; }
  .header-left img {
    height: 20px;
    top: unset;
    position: relative; }
  .side-header {
    display: none; }
  .header-search-input {
    background-color: #f4f6f8;
    padding: 16px 16px 16px 30px;
    margin: 0; }
  .hs-browse-icon {
    left: 12px; }
  .mobile-bottom-header {
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 100%;
    height: 64px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-around;
    z-index: 1001; }
  .for-you-scroller {
    width: calc(100% - 16px); }
  .mbh-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
    color: #1e1f20; }
  .mbh-item span {
    margin-top: 6px;
    font-weight: 600; }
  .mbh-item path {
    color: #1e1f20;
    stroke: #1e1f20;
    fill: #1e1f20; }
  .active-mbh-item,
  .active-mbh-item path {
    color: #30a3ff !important;
    stroke: #30a3ff !important;
    fill: #30a3ff !important; }
  .player {
    bottom: 64px;
    width: 100%;
    z-index: 1001; }
  .wave-holder {
    display: none; }
  .player-preview {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    justify-content: space-between;
    border-radius: 8px 8px 0 0; }
  .pp-bottom {
    margin-top: 0; }
  .pp-time {
    display: none; }
  .pp-nav-prev {
    display: none; }
  .player-open .wave-holder {
    display: block; }
  .player-open .pp-active-title {
    display: none; }
  .player-open .player-preview {
    flex-direction: column;
    align-items: flex-start; }
  .player-open .pp-time {
    display: block; }
  .player-open .pp-bottom {
    margin-top: 21px;
    width: 100%; }
  .player-open .pp-nav-prev {
    display: block; }
  .player-open .pp-top {
    padding-top: 12px; }
  .book-card-grid {
    grid-gap: 20px; }
  .book-card {
    width: 100%; }
  .bc-right {
    flex: 1 1; }
  .bcr-bottom {
    flex-direction: column;
    position: relative; }
    .bcr-bottom .play-button {
      margin-right: 0 !important;
      margin-bottom: 8px; }
  .details-svg {
    top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: -18px; }
  .book-card-holder-long .details-svg {
    -webkit-transform: unset;
            transform: unset; }
  .header-search-active {
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 3; }
    .header-search-active .header-search-input {
      width: 100%;
      background: white;
      border: 1px solid #f4f6f8;
      padding-left: 21px; }
    .header-search-active .hs-close-icon {
      right: 24px; }
    .header-search-active .search-results {
      width: 100%; }
    .header-search-active .result {
      font-size: 14px; }
  .header-search-extend {
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 3; }
    .header-search-extend .header-search-input {
      width: 100%;
      background: white;
      border: 1px solid #f4f6f8;
      padding-left: 21px; }
    .header-search-extend .hs-close-icon {
      right: 24px; }
    .header-search-extend .hs-browse-icon {
      display: none; }
  .browse-image {
    height: 160px;
    align-self: center !important; }
  .category-splash {
    height: 160px; }
  .book-card-cover {
    border-radius: 3px; }
  .bc-right {
    padding: 0;
    margin-left: 12px;
    flex-direction: row; }
  .bcr-bottom-left {
    display: none; }
  .bcr-bottom img {
    width: 36px; }
  .browse-image {
    height: 100px;
    width: 100%;
    background-size: cover; }
  .fyi-image {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    margin: 0 auto; }
  .for-you-item {
    width: 100px; }
  .for-you-item p {
    font-size: 10px;
    font-weight: normal; }
  .browse-item {
    width: 100%;
    position: relative; }
  .browse-item p {
    position: absolute;
    margin-top: 0;
    top: 50%;
    color: white;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: unset; }
  .for-you-header {
    align-items: flex-start; }
  .book-card {
    flex-wrap: wrap; }
  .book-card-holder-long .bcr-bottom-left {
    display: flex;
    overflow: scroll; }
  .book-card-holder-long .bcr-bottom-right {
    position: absolute;
    right: 0;
    top: 40px; }
  .bcr-top {
    flex: 1 1; }
  .book-card-holder-long .bc-right {
    position: relative;
    flex-direction: column; }
  .book-card-holder-long .bcr-bottom {
    flex-direction: column;
    left: 0;
    align-items: flex-start;
    position: relative;
    margin-top: 12px;
    bottom: 0; }
  .book-card-holder-long {
    margin-bottom: 74px; }
  .pop-books-scroller .book-card {
    flex-wrap: nowrap; }
  .pop-books-scroller .book-card-holder {
    min-width: 250px; }
  .pop-books-scroller .bcr-bottom {
    right: 28px; }
  .pop-books-scroller .details-svg {
    right: -8px; }
  .profile-header-right {
    display: none; }
  .book-major-cover {
    width: 170px;
    min-width: 170px; }
    .book-major-cover img {
      width: 100%; }
  .book-page .book-major-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vw;
    background-position: center;
    border-radius: 0;
    z-index: 999; }
  .book-page .bm-right {
    margin-top: calc(100vw - 100px); }
  .book-page .bcr-bottom-left {
    display: flex;
    margin-top: 24px;
    font-size: 14px; }
  .book-page .category-label {
    font-size: 12px;
    padding: 14px 21px; }
  .book-page .book-major {
    padding-bottom: 48px;
    border-bottom: 1px solid #dedede; }
  .book-page .book-header {
    position: fixed;
    z-index: 999;
    bottom: 0;
    background-color: white;
    height: 56px;
    width: 100%;
    left: 0;
    flex-direction: row-reverse;
    align-items: center; }
  .book-page .book-header-right .phr-dets {
    margin-left: 12px;
    height: 30px;
    width: 30px; }
  .book-header-mobile-links {
    position: fixed;
    top: 30px;
    z-index: 99999;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    flex-direction: row-reverse; }
  .book-header-mobile-links.bhml-relative {
    position: absolute;
    top: calc(100vw - 46px);
    align-items: flex-end; }
  .hidden {
    display: none; }
  .sticked .book-header-mobile-links {
    position: fixed !important;
    top: 12px !important;
    background-color: white;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 12px; }
  .sticked .bhml {
    border: 1px solid #e2e2e2; }
  .sticked .book-major-cover {
    position: fixed !important;
    top: unset !important;
    bottom: 100%;
    -webkit-transform: translateY(55px);
            transform: translateY(55px);
    opacity: 0 !important; }
  .sticked .bhmc-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    height: 12px;
    z-index: 9999; }
  .bhml {
    background-color: white;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer; }
  .bhml-flip {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .bhml-right {
    display: flex;
    align-items: center; }
    .bhml-right .bhml:last-of-type {
      margin-left: 12px; }
  .bhml svg path {
    fill: #1e1f20; }
  .playlist-preview {
    margin-right: 0;
    width: 164px;
    padding: 16px;
    height: 214px; }
  .playlist-preview-box {
    margin-right: 8px; }
  .playlist-items .playlist-preview-box:nth-of-type(2n) {
    margin-right: 0; }
  .new-playlist {
    position: fixed;
    bottom: 140px;
    z-index: 999;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    margin: 0; }
  .real-playlist-cover {
    width: 113px;
    height: 149px; }
  .playlist-input {
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #dee2ef;
    padding-left: 12px; }
  .new-playlist-page .np-right-buttons {
    position: fixed;
    bottom: 120px;
    width: 100%;
    left: 0;
    flex-direction: row;
    justify-content: center; }
  .playlist-page .standard-button {
    width: 90px; }
  .playlist-page .standard-button img {
    left: 13px; }
  .playlist-page .np-right-buttons {
    flex-direction: row; }
  .playlist-page h1 {
    font-size: 18px; }
  .notification {
    width: 340px; }
  .book-card-menu {
    display: none; }
  .book-card-menu-mobile-holder {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end; }
  .bcmmh-overlay {
    background-color: #bbbebf;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .bcmm {
    width: 100%;
    z-index: 1;
    background-color: white;
    border-radius: 24px 24px 0 0;
    box-sizing: border-box; }
  .bcmm-top {
    border-bottom: 1px solid #e5e7ea; }
  .bcmm-item {
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between; }
  .bcmm-i-text {
    display: flex;
    align-items: center; }
  .bcmm-i-text svg {
    margin-right: 24px;
    width: 24px; }
  .bcmm-bottom .bcmm-item {
    padding: 12px 24px;
    text-decoration: none;
    color: #000;
    color: initial; }
  .bcmm-bottom-header {
    padding: 24px 24px 12px 24px;
    color: #bbbebf;
    font-size: 14px; }
  .bcmm-i-text {
    font-weight: bold; }
  .bcmm-close {
    display: flex;
    justify-content: center;
    padding: 28px; }
  .bcmm-close-button {
    background-color: #f4f6f8;
    color: inherit; }
  .standard-page {
    margin-left: 0;
    padding-bottom: 175px; }
  .pop-books-scroller .book-card-holder {
    min-width: 350px;
    display: flex;
    flex: 1 1;
    margin-right: 0; }
    .pop-books-scroller .book-card-holder:nth-child(3n - 1) {
      justify-content: flex-start; }
    .pop-books-scroller .book-card-holder:nth-child(3n - 3) {
      justify-content: flex-start;
      margin-right: 0; }
    .pop-books-scroller .book-card-holder:nth-child(2n - 2) {
      justify-content: flex-start;
      margin-right: 0; }
  .playlist-items {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-gap: 36px 0; }
  .playlist-preview-box {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    position: relative;
    justify-self: center; }
    .playlist-preview-box:nth-child(2n - 1) {
      justify-self: center; }
    .playlist-preview-box:nth-child(2n - 2) {
      justify-self: center; }
    .playlist-preview-box:nth-child(3n - 2) {
      justify-self: flex-start; }
    .playlist-preview-box:nth-child(3n - 3) {
      justify-self: flex-end; }
    .playlist-preview-box:hover .playlist-preview {
      opacity: 0.5; }
    .playlist-preview-box:hover .pti-trash {
      display: flex; }
  .author-left {
    max-width: calc(100% - 16px); }
  .author-items {
    margin-right: 0; }
  .author-items .author-item:last-of-type {
    padding-right: 64px; }
  .author-page {
    -ms-overflow-style: none;
    scrollbar-width: none; }
  .author-page::-webkit-scrollbar {
    display: none; }
  .notifications {
    position: fixed;
    top: 20px !important;
    z-index: 9999;
    width: calc(100% - 32px);
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .notification {
    width: 100% !important; }
  .mobile-bottom-header .no-fill-svg path {
    fill: #ffffff !important; }
  .playlist-details {
    display: flex;
    position: absolute;
    right: 16px; }
  .header-search-extend .hs-close-icon {
    display: block !important;
    padding: 12px;
    right: 35px; }
  .playlist-book-cards .book-card-holder {
    margin-bottom: 24px; } }

@media (max-width: 560px) {
  .playlist-items {
    display: grid;
    grid-template-columns: repeat(2, calc(100% / 2));
    grid-gap: 36px 0; }
  .playlist-preview-box {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    position: relative;
    justify-self: center; }
    .playlist-preview-box:nth-child(3n - 2) {
      justify-self: center; }
    .playlist-preview-box:nth-child(3n - 3) {
      justify-self: center; }
    .playlist-preview-box:nth-child(2n - 1) {
      justify-self: flex-start; }
    .playlist-preview-box:nth-child(2n - 2) {
      justify-self: flex-end; }
    .playlist-preview-box:hover .playlist-preview {
      opacity: 0.5; }
    .playlist-preview-box:hover .pti-trash {
      display: flex; }
  .pp-mobile-cover {
    width: 27px;
    height: 41px;
    background-size: cover;
    margin-right: 8px; }
  .player-hover-section {
    display: none !important; }
  .player-open .pp-mobile-cover {
    display: none; }
  .player-open .player-mobile-hover-section {
    display: block;
    padding: 24px 16px 48px; }
    .player-open .player-mobile-hover-section .phs-link img {
      margin-right: 16px; }
    .player-open .player-mobile-hover-section .phs-link {
      display: flex;
      align-items: center; }
    .player-open .player-mobile-hover-section .phs-rating {
      font-size: 18px;
      color: #ff6b56;
      font-weight: bold;
      margin-bottom: 9px; }
  .mpb-holder {
    display: flex; }
  .pos-divider {
    display: none; }
  .pp-top {
    flex: 1 1; }
  .header-search-active .search-results {
    position: fixed;
    left: 0;
    padding: 18px 24px;
    box-shadow: 0px 20px 17px 0 rgba(0, 0, 0, 0.08);
    border-top: 1px solid rgba(187, 190, 191, 0.5);
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .header-search-extend {
    position: fixed;
    top: 0;
    z-index: 999999999999;
    height: 100px;
    left: 0;
    top: 0;
    padding: 0 24px;
    background: white;
    box-sizing: border-box;
    border-bottom: 1px solid transparent; } }

@media (max-width: 375px) {
  .player-open .player-open-section {
    max-height: 40vh; }
  .book-card-grid {
    grid-template-columns: 100%; }
  .browse-image {
    width: 100%;
    height: 129px; }
  .category-splash {
    height: 129px; }
  .browse-item {
    width: 100%;
    text-align: center; }
  .browse-item p {
    display: block;
    text-align: center;
    width: 100%; }
  .category-section-header {
    flex-direction: column; }
  .sort-toggle {
    margin: -20px 0 20px;
    width: 99%; }
    @supports (-ms-ime-align: auto) {
      .sort-toggle {
        width: 99%; } }
  .sort-toggle div {
    flex: 1 1; }
  .abl-image {
    min-width: 72px; }
  .fyh-fb {
    display: flex; }
  .bcr-bottom img {
    width: 27px; }
  .playlist-preview {
    width: 140px;
    height: 190px; }
  .delete-np {
    margin-right: 6px; }
  .notification {
    width: 290px; }
  .notif-content {
    padding-right: 12px; }
  .mms-big-pic {
    width: 75px;
    height: 75px; } }

.grey {
  color: #BBBEBF; }

/* Old Browser */
.old-browser-support {
  font-family: "Poppins", sans-serif;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-image: none !important;
  text-align: center;
  background-color: black !important;
  font-size: 20px;
  color: #fff;
  z-index: 100000000000000000000000000000000000;
  line-height: 1.6; }
  .old-browser-support p {
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 1.6;
    font-family: inherit; }
  .old-browser-support a {
    color: red; }

.btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.233333px;
  padding-left: 32px;
  padding-right: 32px;
  height: 40px;
  background: #1E1F20;
  border-radius: 20px;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  outline: 0 !important;
  text-decoration: none; }
  .btn:hover, .btn:active, .btn:focus, .btn:visited {
    color: #fff; }
  .btn:hover {
    background-color: rgba(30, 31, 32, 0.8); }
  @media (max-width: 991px) {
    .btn {
      height: 32px;
      padding-left: 17px;
      padding-right: 17px;
      font-size: 14px; } }

.btn-empty {
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 48px;
  min-width: 170px;
  background: #30A3FF;
  border-radius: 24px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 14px;
  color: #fff !important;
  letter-spacing: normal;
  font-weight: 500; }
  .btn-empty:hover, .btn-empty:active, .btn-empty:focus {
    background-color: rgba(48, 163, 255, 0.8); }
  @media (max-width: 991px) {
    .btn-empty {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 13px; } }

.btn-primary {
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 48px;
  min-width: 170px;
  background: #30A3FF;
  border-radius: 24px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 14px;
  color: #fff !important;
  letter-spacing: normal;
  font-weight: 500; }
  .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color: rgba(48, 163, 255, 0.8); }
  @media (max-width: 991px) {
    .btn-primary {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 13px; } }

.nav {
  background-color: #fff;
  z-index: 250;
  position: relative;
  max-width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  display: flex;
  align-items: center;
  width: 100%; }
  .nav .nav-container {
    display: flex;
    align-items: center;
    max-width: 1400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .nav .nav-container .part {
      width: calc(100% / 3); }
      .nav .nav-container .part:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
      .nav .nav-container .part:nth-child(2) {
        justify-content: center;
        display: flex;
        align-items: center; }
      .nav .nav-container .part:nth-child(3) {
        display: flex;
        align-items: center;
        justify-content: flex-end; }
  .nav .logo {
    display: flex;
    align-items: center;
    cursor: pointer; }
    .nav .logo img {
      height: 25px;
      object-fit: contain; }
  .nav .socials {
    display: flex;
    align-items: center; }
    .nav .socials a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 1px solid #DEE2E5;
      margin: 0 8px;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease; }
      .nav .socials a img {
        object-fit: contain;
        display: block; }
      .nav .socials a.social-google img {
        -webkit-transform: translateX(2px);
                transform: translateX(2px); }
      .nav .socials a:hover {
        border-color: rgba(222, 226, 229, 0.4);
        background-color: rgba(222, 226, 229, 0.4); }
  .nav .btns-wrap {
    display: flex;
    align-items: center; }
    .nav .btns-wrap .link {
      margin-right: 30px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.23px;
      text-align: center;
      color: #2D92FF;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease; }
      .nav .btns-wrap .link:hover {
        color: rgba(45, 146, 255, 0.8); }
    .nav .btns-wrap .btn:nth-child(2) {
      margin-left: 8px; }
  @media (max-width: 991px) {
    .nav {
      display: none; } }

.nav-mobile {
  margin-top: 20px;
  display: none;
  height: 65px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%; }
  @media (max-width: 991px) {
    .nav-mobile {
      display: flex; } }
  .nav-mobile .nav-container {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .nav-mobile .logo {
    display: flex;
    align-items: center; }
  .nav-mobile .btns-wrap {
    display: flex;
    align-items: center; }
    .nav-mobile .btns-wrap .btn {
      line-height: 1; }
    .nav-mobile .btns-wrap .link {
      margin-right: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #2D92FF;
      line-height: 1;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease; }
      .nav-mobile .btns-wrap .link:hover {
        color: rgba(45, 146, 255, 0.8); }
  @media (max-width: 355px) {
    .nav-mobile .nav-container {
      width: calc(100% - 20px); }
    .nav-mobile .logo img {
      max-width: 125px; }
    .nav-mobile .btns-wrap .link {
      margin-right: 12px;
      font-size: 13.5px; }
    .nav-mobile .btns-wrap .btn {
      height: 30px;
      font-size: 13.5px; } }

.footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  text-align: center;
  height: 100px;
  padding-left: 80px;
  padding-right: 80px; }
  @media (max-width: 1366px) {
    .footer {
      max-width: calc(100% - 60px); } }
  .footer .instagram-btn {
    position: relative;
    width: 170px;
    height: 50px;
    background: -webkit-linear-gradient(45deg, #FFC926 9.15%, #FF5346 49%, #C536DE 100%);
    background: linear-gradient(45deg, #FFC926 9.15%, #FF5346 49%, #C536DE 100%);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease; }
    .footer .instagram-btn .wrapper {
      text-align: center;
      position: absolute;
      z-index: 0;
      background-color: #fff;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding-left: 20px;
      border-radius: 25px;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease; }
    .footer .instagram-btn .icon {
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20px;
      margin: auto;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease; }
      .footer .instagram-btn .icon .icon-wrap {
        -webkit-transform: translateY(-1px);
                transform: translateY(-1px);
        position: relative;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .footer .instagram-btn .icon .icon-wrap svg {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          -webkit-transition: 0.3s all ease;
          transition: 0.3s all ease; }
          .footer .instagram-btn .icon .icon-wrap svg * {
            opacity: 1;
            -webkit-transition: 0.3s all ease;
            transition: 0.3s all ease; }
          .footer .instagram-btn .icon .icon-wrap svg:nth-child(1) {
            opacity: 1; }
          .footer .instagram-btn .icon .icon-wrap svg:nth-child(2) {
            opacity: 0; }
    .footer .instagram-btn .label {
      padding-left: 12px;
      z-index: 0;
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      color: #1E1F20;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease; }
    .footer .instagram-btn:hover .wrapper {
      background-color: transparent; }
    .footer .instagram-btn:hover .label {
      color: #fff; }
    .footer .instagram-btn:hover .icon svg:nth-child(1) {
      opacity: 0; }
    .footer .instagram-btn:hover .icon svg:nth-child(2) {
      opacity: 1; }
  .footer .footer-copyright {
    text-align: center;
    display: block;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.2px;
    color: #BBBEBF; }
  .footer .footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }
    .footer .footer-logo img {
      object-fit: contain; }
  @media (max-width: 660px) {
    .footer {
      height: auto;
      max-width: 100%;
      padding: 40px 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .footer .footer-copyright {
        margin-top: 30px;
        margin-bottom: 30px; } }

.onepage-template {
  width: 100%;
  height: calc(100vh - 100px);
  min-height: 750px;
  display: flex;
  justify-content: center;
  align-items: center; }

.auth-template {
  width: 100%;
  height: 100%;
  max-height: 900px;
  padding-bottom: 80px; }
  .auth-template .columns-wrap {
    padding: 0 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: calc(1600px - 160px);
    margin-left: auto;
    margin-right: auto; }
    .auth-template .columns-wrap .col-form {
      padding-top: 40px;
      padding-bottom: 40px;
      padding-right: 100px;
      width: 55%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .auth-template .columns-wrap .col-grid {
      width: 45%;
      display: flex;
      align-items: center; }
      .auth-template .columns-wrap .col-grid .grid-wrapper {
        position: relative;
        border-radius: 30px;
        width: 100%;
        background-image: url("/img/grid.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        height: calc(100% - 40px); }
    @media (max-width: 1366px) {
      .auth-template .columns-wrap .col-form {
        width: 50%; }
      .auth-template .columns-wrap .col-grid {
        width: 50%; } }
  @media (max-width: 991px) {
    .auth-template {
      padding-bottom: 40px; }
      .auth-template .columns-wrap {
        width: 100%;
        padding: 0 15px; }
        .auth-template .columns-wrap .col-form {
          width: 100%;
          padding: 0; }
        .auth-template .columns-wrap .col-grid {
          display: none; } }

.auth-form {
  width: 330px; }
  .auth-form .form-title {
    color: #212126;
    display: block;
    margin-bottom: 40px; }
  .auth-form .form-group {
    margin-bottom: 25px;
    display: block; }
    .auth-form .form-group label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #BBBEBF;
      display: inline-block;
      margin-bottom: 4px;
      margin-left: 20px; }
    .auth-form .form-group input {
      padding-left: 20px;
      padding-right: 20px;
      display: block;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #EBEEF0;
      border-radius: 20px;
      height: 40px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: #1E1F20; }
      .auth-form .form-group input::-webkit-input-placeholder {
        font-family: "Roboto", sans-serif;
        color: #1E1F20;
        font-size: 14px; }
      .auth-form .form-group input::-moz-placeholder {
        font-family: "Roboto", sans-serif;
        color: #1E1F20;
        font-size: 14px; }
      .auth-form .form-group input:-ms-input-placeholder {
        font-family: "Roboto", sans-serif;
        color: #1E1F20;
        font-size: 14px; }
      .auth-form .form-group input::-ms-input-placeholder {
        font-family: "Roboto", sans-serif;
        color: #1E1F20;
        font-size: 14px; }
      .auth-form .form-group input::placeholder {
        font-family: "Roboto", sans-serif;
        color: #1E1F20;
        font-size: 14px; }
      .auth-form .form-group input[type=password] {
        font-size: 22px;
        letter-spacing: 5px; }
  .auth-form .submit-group {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .auth-form .submit-group button {
      cursor: pointer;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 48px;
      min-width: 170px;
      background: #30A3FF;
      border-radius: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-size: 14px;
      color: #fff !important;
      letter-spacing: normal;
      font-weight: 500;
      width: 150px;
      min-width: 150px;
      height: 40px;
      padding-left: 0px;
      padding-right: 10px; }
      .auth-form .submit-group button:hover, .auth-form .submit-group button:active, .auth-form .submit-group button:focus {
        background-color: rgba(48, 163, 255, 0.8); }
      @media (max-width: 991px) {
        .auth-form .submit-group button {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 13px; } }
      .auth-form .submit-group button .label {
        text-transform: capitalize;
        font-weight: 500; }
      .auth-form .submit-group button .icon {
        display: flex;
        align-items: center;
        line-height: 1; }
        .auth-form .submit-group button .icon path {
          fill: #fff; }
    .auth-form .submit-group .reset-password {
      cursor: pointer;
      color: #2D92FF;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      margin: 15px 0; }
  .auth-form hr {
    margin-top: 38px;
    margin-bottom: 30px;
    background-color: #DEE2E5;
    height: 1px;
    width: 100%;
    display: block;
    border: 0;
    padding: 0; }
  .auth-form .socials-group .btns-wrap {
    margin-top: 12px;
    display: flex;
    justify-content: space-between; }
    .auth-form .socials-group .btns-wrap a {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background: #F4F6F8;
      border-radius: 20px;
      cursor: pointer;
      padding-left: 12px;
      padding-right: 12px;
      height: 40px;
      width: calc(100% / 2 - 8px);
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease; }
      .auth-form .socials-group .btns-wrap a:hover {
        background-color: rgba(244, 246, 248, 0.8); }
      .auth-form .socials-group .btns-wrap a .icon {
        display: flex;
        align-items: center;
        position: absolute;
        left: 12px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
      .auth-form .socials-group .btns-wrap a .label {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        letter-spacing: -0.233333px;
        color: #1E1F20;
        line-height: 1; }
  .auth-form .cta {
    margin-top: 40px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #1E1F20; }
    @media (max-width: 991px) {
      .auth-form .cta br {
        display: none; } }
    .auth-form .cta a {
      color: #30A3FF; }

body {
  max-width: 2000px;
  overflow-x: hidden !important;
  margin-left: auto;
  margin-right: auto; }

a {
  text-decoration: none; }

.template-wrapper {
  max-width: 100vw;
  overflow-x: hidden !important; }

.grid-scroll {
  height: 0;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  overflow: hidden; }

.grid-styled {
  min-height: 100%;
  height: auto;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .grid-styled .book-item {
    width: 200px;
    height: 290px;
    background-color: #fff;
    border-radius: 30px;
    display: block;
    margin: 40px 0;
    box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.20419);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transition: 0.65s all ease;
    transition: 0.65s all ease; }
    .grid-styled .book-item.faded {
      opacity: 0; }
    .grid-styled .book-item:first-child {
      margin-top: 0; }
    .grid-styled .book-item:last-child {
      margin-bottom: 0; }
  .grid-styled .col-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start; }
  .grid-styled .col-right {
    padding-top: 65px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }

.section-contact {
  margin-top: 200px;
  height: 840px;
  display: flex;
  justify-content: space-between; }
  .section-contact .image-part {
    width: 50%;
    height: 100%;
    background-image: url("/public/img/contact.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom; }
  .section-contact .content-part {
    padding-right: 80px;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center; }
    .section-contact .content-part .content .section-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 900;
      letter-spacing: -0.28px;
      color: #212126;
      display: block;
      margin-bottom: 20px;
      line-height: 1.6; }
    .section-contact .content-part .content .section-subtitle {
      margin-bottom: 50px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 22px;
      letter-spacing: -0.4px;
      color: #212127;
      max-width: 700px; }
    .section-contact .content-part .content hr {
      width: 100%;
      display: block;
      height: 1px;
      margin: 48px 0;
      background: #BBBEBF;
      mix-blend-mode: normal;
      opacity: 0.3; }
    .section-contact .content-part .content .socials-btn {
      display: flex;
      align-items: center; }
      .section-contact .content-part .content .socials-btn a {
        padding-left: 18px;
        padding-right: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #DEE2E5;
        border-radius: 24px;
        width: 170px;
        height: 50px;
        -webkit-transition: 0.3s all ease;
        transition: 0.3s all ease; }
        .section-contact .content-part .content .socials-btn a:first-child {
          margin-right: 24px; }
        .section-contact .content-part .content .socials-btn a .icon {
          display: flex;
          align-items: center; }
        .section-contact .content-part .content .socials-btn a .label {
          display: flex;
          align-items: center;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          letter-spacing: -0.233333px;
          color: #1E1F20; }
        .section-contact .content-part .content .socials-btn a .arrow {
          display: flex;
          align-items: center; }
        .section-contact .content-part .content .socials-btn a:hover {
          border-color: rgba(222, 226, 229, 0.4);
          background-color: rgba(222, 226, 229, 0.4); }
  @media (max-width: 1440px) {
    .section-contact {
      height: 660px;
      margin-top: 100px; } }
  @media (max-width: 1366px) {
    .section-contact .content-part {
      padding-right: 40px; }
      .section-contact .content-part .content .section-subtitle {
        font-size: 20px; } }
  @media (max-width: 1199px) {
    .section-contact {
      height: 550px; }
      .section-contact .content-part .content .section-subtitle {
        font-size: 18px;
        margin-bottom: 35px; }
      .section-contact .content-part .content hr {
        margin: 35px 0; } }
  @media (max-width: 991px) {
    .section-contact {
      flex-direction: column-reverse;
      height: auto;
      margin-top: 0; }
      .section-contact .content-part {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 65px;
        padding-bottom: 0px;
        width: 100%;
        max-width: 100%; }
        .section-contact .content-part .content {
          display: block;
          width: 100%;
          max-width: 600px;
          margin-left: auto;
          margin-right: auto; }
      .section-contact .image-part {
        position: relative;
        width: 100%;
        max-width: 100%; }
        .section-contact .image-part:after {
          display: block;
          content: "";
          padding-bottom: 110%; } }
  @media (max-width: 340px) {
    .section-contact .content-part .content .socials-btn {
      flex-direction: column;
      align-items: flex-start; }
      .section-contact .content-part .content .socials-btn a {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 15px; }
        .section-contact .content-part .content .socials-btn a:last-child {
          margin-bottom: 0; } }

