.mobile-menu-section {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 56px);
  background-color: white;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;
  padding: 36px;
  display: none;
  overflow: scroll;
}

.mobile-menu-home-header {
  text-align: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #e5e7ea;
}

.mobile-menu-home-header img {
  width: 72px;
  height: 72px;
  display: block;
  margin: 0 auto 12px;
}

.mobile-menu-home-header span {
  font-size: 18px;
  font-weight: bold;
}

.mmh-links {
  margin-top: 36px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.mmh-links a,
.mmh-links span {
  display: block;
  color: inherit;
  text-decoration: none;
  font-size: 18px;
  margin-bottom: 21px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.notif-number {
  margin-bottom: 0 !important;
  position: absolute !important;
  margin-left: 70px;
  background-color: #ff6b56;
  color: white !important;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-size: 14px !important;
  top: 50%;
  transform: translateY(-50%);
}

.mobile-menu-section .logout-button {
  margin: 12px auto 0;
  background-color: #e5e7ea;
  color: inherit;
}

.mobile-menu-section .support-button {
  position: absolute;
  margin: 0;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.mobile-settings-header {
  display: flex;
  justify-content: space-between;
}

.mobile-settings-header .phr-dets {
  margin: 0;
  border: 1px solid #e5e7ea;
  cursor: pointer;
}

.mms-big-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid #e5e7ea;
}

.mms-pic {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.mms-big-pic {
  position: relative;
  cursor: pointer;
}

.mms-big-pic svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: none;
}

.mms-big-pic:hover {
  opacity: 0.5;
  svg {
    display: block;
  }
}
.mobile-settings-options {
  margin: 36px 0;
  display: flex;
}

.mobile-settings-options span {
  font-size: 12px;
  font-weight: 600;
  margin-right: 36px;
  color: #d8d8d8;
  cursor: pointer;
}

.mso-active {
  color: #1e1f20 !important;
}

.save-changes {
  background-color: #30a3ff;
  margin-top: 24px;
}

.delete-i {
  color: #ff6b56;
  font-size: 14px;
  cursor: pointer;
  svg {
    path {
      fill: #ff6b56;
    }
  }
}

.bcmm-bottom {
}

.delete-sure {
  padding: 36px 24px;
}

.delete-sure h2 {
  font-size: 24px;
  line-height: 1.2;
}

.delete-bar {
  margin-top: 28px;
  height: 64px;
  border: 1px solid #e5e7ea;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
}

.delete-bar img {
  margin-right: 16px;
}

.delete-buttons {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}

.delete-buttons .standard-button:first-of-type {
  background-color: #e5e7ea;
  color: inherit;
}

.delete-buttons .standard-button:last-of-type {
  background-color: #ff6b56;
}

.mn-header {
  align-items: center;
}

.notif-title {
  font-size: 21px;
}

.mobile-menu-section .notifications-tab {
  position: relative;
  height: unset;
  right: unset;
  padding: 0;
  max-width: 100%;
}
