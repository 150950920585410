@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700,900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  color: #1e1f20;
  -webkit-font-smoothing: antialiased;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  -ms-overflow-style: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 32px;
  margin-bottom: 40px;
}

p {
  font-size: 16px;
}

.container {
  max-width: 1080px;
  flex-basis: 1080px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}
