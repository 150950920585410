body {
  max-width: 2000px;
  overflow-x: hidden !important;
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}

.template-wrapper {
  max-width: 100vw;
  overflow-x: hidden !important;
}

// Grid Animation
.grid-scroll {
  height: 0;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  overflow: hidden;
}

.grid-styled {
  min-height: 100%;
  height: auto;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .book-item {
    width: 200px;
    height: 290px;
    background-color: #fff;
    border-radius: 30px;
    display: block;
    margin: 40px 0;
    box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.20419);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: 0.65s all ease;

    &.faded {
      opacity: 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .col-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .col-right {
    padding-top: 65px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}


// Section Contact
.section-contact {
  margin-top: 200px;
  height: 840px;
  display: flex;
  justify-content: space-between;

  .image-part {
    width: 50%;
    height: 100%;
    background-image: url('/public/img/contact.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
  }

  .content-part {
    padding-right: 80px;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    .content {
      .section-title {
        font-family: $font;
        font-style: normal;
        font-weight: 900;
        letter-spacing: -0.28px;
        color: #212126;
        display: block;
        margin-bottom: 20px;
        line-height: 1.6;
      }
      .section-subtitle {
        margin-bottom: 50px;
        font-family: $font;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        letter-spacing: -0.4px;
        color: #212127;
        max-width: 700px;
      }
      hr {
        width: 100%;
        display: block;
        height: 1px;
        margin: 48px 0;
        background: #BBBEBF;
        mix-blend-mode: normal;
        opacity: 0.3;
      }
      .socials-btn {
        display: flex;
        align-items: center;
        a {
          &:first-child {
            margin-right: 24px;
          }
          padding-left: 18px;
          padding-right: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #FFFFFF;
          border: 1px solid #DEE2E5;
          border-radius: 24px;
          width: 170px;
          height: 50px;
          .icon {
            display: flex;
            align-items: center;
          }
          .label {
            display: flex;
            align-items: center;
            font-family: $font;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            letter-spacing: -0.233333px;
            color: #1E1F20;
          }
          .arrow {
            display: flex;
            align-items: center;
          }
          @include transition;

          &:hover {
            border-color: rgba(#DEE2E5, .4);
            background-color: rgba(#DEE2E5, .4);
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    height: 660px;
    margin-top: 100px;
  }
  @media (max-width: 1366px) {
    .content-part {
      padding-right: 40px;

      .content {
        .section-subtitle {
          font-size: 20px;
        }
      }
    }
  }
  @media (max-width: 1199px) {
    height: 550px;
    .content-part {
      .content {
        .section-subtitle {
          font-size: 18px;
          margin-bottom: 35px;
        }

        hr {
          margin: 35px 0;
        }
      }
    }
  }
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    height: auto;
    margin-top: 0;
    .content-part {
      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 65px;
      padding-bottom: 0px;
      width: 100%;
      max-width: 100%;

      .content {
        display: block;
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .image-part {
      position: relative;
      width: 100%;
      max-width: 100%;

      &:after {
        display: block;
        content: "";
        padding-bottom: 110%;
      }
    }
  }
  @media (max-width: 340px) {
    .content-part {
      .content {
        .socials-btn {
          flex-direction: column;
          align-items: flex-start;

          a {
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

}