@media (max-width: 768px) {
  .container {
    padding-left: 12px;
    padding-right: 12px;
  }
  .mobile-menu-section {
    display: block;
  }
  .header-right {
    display: none;
  }

  .header-search {
    width: 95px;
    flex: unset;
    padding-right: 0;
    height: unset;
  }

  .header-left img {
    height: 20px;
    top: unset;
    position: relative;
  }

  .side-header {
    display: none;
  }

  .header-search-input {
    background-color: #f4f6f8;
    padding: 16px 16px 16px 30px;
    margin: 0;
  }

  .hs-browse-icon {
    left: 12px;
  }
  .mobile-bottom-header {
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 100%;
    height: 64px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-around;
    z-index: 1001;
  }

  .for-you-scroller {
    width: calc(100% - 16px);
  }

  .mbh-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
    color: #1e1f20;
  }

  .mbh-item span {
    margin-top: 6px;
    font-weight: 600;
  }

  .mbh-item path {
    color: #1e1f20;
    stroke: #1e1f20;
    fill: #1e1f20;
  }

  .active-mbh-item,
  .active-mbh-item path {
    color: #30a3ff !important;
    stroke: #30a3ff !important;
    fill: #30a3ff !important;
  }

  .player {
    bottom: 64px;
    width: 100%;
    z-index: 1001;
  }

  .wave-holder {
    display: none;
  }

  .player-preview {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
  }

  .pp-bottom {
    margin-top: 0;
  }

  .pp-time {
    display: none;
  }
  .pp-nav-prev {
    display: none;
  }
  .player-open {
    .wave-holder {
      display: block;
    }

    .pp-active-title {
      display: none;
    }

    .player-preview {
      flex-direction: column;
      align-items: flex-start;
    }

    .pp-time {
      display: block;
    }

    .pp-bottom {
      margin-top: 21px;
      width: 100%;
    }

    .pp-nav-prev {
      display: block;
    }

    .pp-top {
      padding-top: 12px;
    }
  }
  .book-card-grid {
    grid-gap: 20px;
  }

  .book-card {
    width: 100%;
  }

  .bc-right {
    flex: 1;
  }

  .bcr-bottom {
    flex-direction: column;
    // align-items: center;
    position: relative;
    .play-button {
      margin-right: 0!important;
      margin-bottom: 8px;
    }
  }

  .details-svg {
    top: 0;
    transform: translateY(-50%);
    right: -18px;
  }
  .book-card-holder-long .details-svg {
    transform: unset;
  }
  .header-search-active {
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 3;
    .header-search-input {
      width: 100%;
      background: white;
      border: 1px solid #f4f6f8;
      padding-left: 21px;
    }
    .hs-close-icon {
      right: 24px;
    }

    .search-results {
      width: 100%;
    }
    .result {
      font-size: 14px;
    }
  }
  .header-search-extend {
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 3;
    .header-search-input {
      width: 100%;
      background: white;
      border: 1px solid #f4f6f8;
      padding-left: 21px;
    }
    .hs-close-icon {
      right: 24px;
    }
    .hs-browse-icon {
      display: none;
    }
  }
  .browse-grid {
    // grid-template-columns: 100%;
  }
  .browse-image {
    // width: 298px;
    height: 160px;
    align-self: center !important;
  }

  .category-splash {
    height: 160px;
  }
  .book-card-cover {
    // width: 45px;
    border-radius: 3px;
  }

  .bc-right {
    padding: 0;
    margin-left: 12px;
    flex-direction: row;
  }

  .bcr-bottom-left {
    display: none;
  }

  .bcr-bottom img {
    width: 36px;
  }

  .browse-image {
    height: 100px;
    width: 100%;
    background-size: cover;
  }

  .fyi-image {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    margin: 0 auto;
  }

  .for-you-item {
    width: 100px;
  }

  .for-you-item p {
    font-size: 10px;
    font-weight: normal;
  }

  .browse-item {
    width: 100%;
    position: relative;
  }

  .browse-item p {
    position: absolute;
    margin-top: 0;
    top: 50%;
    color: white;
    transform: translateY(-50%);
    left: unset;
  }

  .browse-grid {
    // grid-gap: 20px 0;
  }
  .for-you-header {
    align-items: flex-start;
  }
  .book-card-holder-long .bcr-right {
  }

  .book-card {
    flex-wrap: wrap;
  }

  // .book-card-cover {
  //   width: 100px;
  //   height: 150px;
  //   background-size: cover;
  // }

  .book-card-holder-long .bcr-bottom-left {
    display: flex;
    overflow: scroll;
  }

  .book-card-holder-long .bcr-bottom-right {
    position: absolute;
    right: 0;
    top: 40px;
  }

  .bcr-top {
    flex: 1;
  }

  .book-card-holder-long .bc-right {
    position: relative;
    flex-direction: column;
  }

  .book-card-holder-long .bcr-bottom {
    flex-direction: column;
    left: 0;
    align-items: flex-start;
    position: relative;
    margin-top: 12px;
    bottom: 0;
  }

  .book-card-holder-long {
    margin-bottom: 74px;
  }

  .pop-books-scroller {
    .book-card {
      flex-wrap: nowrap;
    }
    .book-card-holder {
      min-width: 250px;
    }
    .bcr-bottom {
      right: 28px;
    }

    .details-svg {
      right: -8px;
    }
  }
  .profile-header-right {
    display: none;
  }
  .book-major-cover {
    width: 170px;
    min-width: 170px;
    img {
      width: 100%;
    }
  }
  .book-page {
    .book-major-cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vw;
      background-position: center;
      border-radius: 0;
      z-index: 999;
    }

    .bm-right {
      margin-top: calc(100vw - 100px);
    }

    .bcr-bottom-left {
      display: flex;
      margin-top: 24px;
      font-size: 14px;
    }

    .category-label {
      font-size: 12px;
      padding: 14px 21px;
    }

    .book-major {
      padding-bottom: 48px;
      border-bottom: 1px solid #dedede;
    }

    .book-header {
      position: fixed;
      z-index: 999;
      bottom: 0;
      background-color: white;
      height: 56px;
      width: 100%;
      left: 0;
      flex-direction: row-reverse;
      align-items: center;
    }

    .book-header-right .phr-dets {
      margin-left: 12px;
      height: 30px;
      width: 30px;
    }
  }
  .book-header-mobile-links {
    position: fixed;
    top: 30px;
    z-index: 99999;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    flex-direction: row-reverse;
  }
  .book-header-mobile-links.bhml-relative {
    position: absolute;
    top: calc(100vw - 46px);
    align-items: flex-end;
  }
  .hidden {
    display: none;
  }

  .sticked {
    .book-header-mobile-links {
      position: fixed !important;
      top: 12px !important;
      background-color: white;
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 12px;
    }

    .bhml {
      border: 1px solid #e2e2e2;
    }

    .book-major-cover {
      position: fixed !important;
      top: unset !important;
      bottom: 100%;
      transform: translateY(55px);
      opacity: 0 !important;
    }
    .bhmc-cover {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: white;
      height: 12px;
      z-index: 9999;
    }
  }

  .bhml {
    background-color: white;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .bhml-flip {
    transform: rotate(180deg);
  }

  .bhml-right {
    display: flex;
    align-items: center;
    .bhml:last-of-type {
      margin-left: 12px;
    }
  }

  .bhml svg path {
    fill: #1e1f20;
  }
  .playlist-preview {
    margin-right: 0;
    width: 164px;
    padding: 16px;
    height: 214px;
  }
  .playlist-preview-box {
    margin-right: 8px;
  }

  .playlist-items .playlist-preview-box:nth-of-type(2n) {
    margin-right: 0;
  }
  .new-playlist {
    position: fixed;
    bottom: 140px;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
  .real-playlist-cover {
    width: 113px;
    height: 149px;
  }

  .playlist-input {
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #dee2ef;
    padding-left: 12px;
  }
  .new-playlist-page {
    .np-right-buttons {
      position: fixed;
      bottom: 120px;
      width: 100%;
      left: 0;
      flex-direction: row;
      justify-content: center;
    }
  }
  .playlist-page {
    .delete-np {
    }

    .standard-button {
      width: 90px;
    }

    .standard-button img {
      left: 13px;
    }

    .np-right-buttons {
      flex-direction: row;
    }

    h1 {
      font-size: 18px;
    }

    .book-card-holder-long .bcr-bottom-left {
      // display: none;
    }

    .book-card-holder-long .bcr-bottom-right {
      // position: relative;
      // top: 0;
    }

    .book-card-holder-long .bcr-bottom {
      // position: relative;
    }

    .bc-right {
      // align-items: center;
    }
  }
  .notification {
    width: 340px;
  }
  .book-card-menu {
    display: none;
  }
  .book-card-menu-mobile-holder {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  .bcmmh-overlay {
    background-color: #bbbebf;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bcmm {
    width: 100%;
    z-index: 1;
    background-color: white;
    border-radius: 24px 24px 0 0;
    box-sizing: border-box;
  }

  .bcmm-top {
    border-bottom: 1px solid #e5e7ea;
  }

  .bcmm-item {
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  .bcmm-i-text {
    display: flex;
    align-items: center;
  }

  .bcmm-i-text svg {
    margin-right: 24px;
    width: 24px;
  }
  .bcmm-bottom .bcmm-item {
    padding: 12px 24px;
    text-decoration: none;
    color: initial;
  }

  .bcmm-bottom-header {
    padding: 24px 24px 12px 24px;
    color: #bbbebf;
    font-size: 14px;
  }

  .bcmm-i-text {
    font-weight: bold;
  }
  .bcmm-close {
    display: flex;
    justify-content: center;
    padding: 28px;
  }
  .bcmm-close-button {
    background-color: #f4f6f8;
    color: inherit;
  }
  .standard-page {
    margin-left: 0;
    padding-bottom: 175px;
  }
  .pop-books-scroller .book-card-holder {
    min-width: 350px;
    display: flex;
    flex: 1;
    margin-right: 0;
    &:nth-child(3n - 1) {
      justify-content: flex-start;
    }
    &:nth-child(3n - 3) {
      justify-content: flex-start;
      margin-right: 0;
    }
    &:nth-child(2n - 2) {
      justify-content: flex-start;
      margin-right: 0;
    }
  }
  .playlist-items {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-gap: 36px 0;
  }
  .playlist-preview-box {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    position: relative;
    justify-self: center;
    &:nth-child(2n - 1) {
      justify-self: center;
    }
    &:nth-child(2n - 2) {
      justify-self: center;
    }
    &:nth-child(3n - 2) {
      justify-self: flex-start;
    }
    &:nth-child(3n - 3) {
      justify-self: flex-end;
    }
    &:hover {
      .playlist-preview {
        opacity: 0.5;
      }
      .pti-trash {
        display: flex;
      }
    }
  }

  .author-left {
    max-width: calc(100% - 16px);
  }

  .author-items {
    margin-right: 0;
  }

  .author-items .author-item:last-of-type {
    padding-right: 64px;
  }

  .author-page {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .author-page::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
  .notifications {
    position: fixed;
    top: 20px !important;
    z-index: 9999;
    width: calc(100% - 32px);
    left: 50%;
    transform: translateX(-50%);
  }

  .notification {
    width: 100% !important;
  }
  .mobile-bottom-header {
    .no-fill-svg {
      path {
        fill: #ffffff !important;
      }
    }
  }
  .playlist-details {
    display: flex;
    position: absolute;
    right: 16px;
  }

  .header-search-extend .hs-close-icon {
    display: block !important;
    padding: 12px;
    right: 35px;
  }

  .playlist-book-cards {
    .book-card-holder {
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 560px) {
  .playlist-items {
    display: grid;
    grid-template-columns: repeat(2, calc(100% / 2));
    grid-gap: 36px 0;
  }
  .playlist-preview-box {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    position: relative;
    justify-self: center;
    &:nth-child(3n - 2) {
      justify-self: center;
    }
    &:nth-child(3n - 3) {
      justify-self: center;
    }
    &:nth-child(2n - 1) {
      justify-self: flex-start;
    }
    &:nth-child(2n - 2) {
      justify-self: flex-end;
    }
    &:hover {
      .playlist-preview {
        opacity: 0.5;
      }
      .pti-trash {
        display: flex;
      }
    }
  }
  .pp-mobile-cover {
    width: 27px;
    height: 41px;
    background-size: cover;
    margin-right: 8px;
  }
  .player-hover-section {
    display: none !important;
  }
  .player-open {
    .pp-mobile-cover {
      display: none;
    }
    .player-mobile-hover-section {
      display: block;
      padding: 24px 16px 48px;
      .phs-link img {
        margin-right: 16px;
      }

      .phs-link {
        display: flex;
        align-items: center;
      }

      .phs-rating {
        font-size: 18px;
        color: #ff6b56;
        font-weight: bold;
        margin-bottom: 9px;
      }
    }
  }
  .mpb-holder {
    display: flex;
  }
  .pos-divider {
    display: none;
  }
  .pp-top {
    flex: 1;
  }
  .header-search-active .search-results {
    position: fixed;
    left: 0;
    padding: 18px 24px;
    box-shadow: 0px 20px 17px 0 rgba(0, 0, 0, 0.08);
    border-top: 1px solid rgba(187, 190, 191, 0.5);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .header-search-extend {
    position: fixed;
    top: 0;
    z-index: 999999999999;
    height: 100px;
    left: 0;
    top: 0;
    padding: 0 24px;
    background: white;
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
  }
}
