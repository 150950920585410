@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');

$font: "Roboto", sans-serif;

$color-dark: #212126;
$color-grey: #BBBEBF;
$color-blue: #2D92FF;

.grey {
  color: $color-grey;
}