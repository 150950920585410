.footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  text-align: center;
  height: 100px;
  padding-left: 80px;
  padding-right: 80px;
  @media (max-width: 1366px) {
    max-width: calc(100% - 60px);
  }

  .instagram-btn {
    position: relative;
    width: 170px;
    height: 50px;
    background: linear-gradient(45deg, #FFC926 9.15%, #FF5346 49%, #C536DE 100%);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include transition;

    .wrapper {
      text-align: center;
      position: absolute;
      z-index: 0;
      background-color: #fff;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding-left: 20px;
      border-radius: 25px;
      @include transition;
    }

    .icon {
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20px;
      margin: auto;
      @include transition;

      .icon-wrap {
        transform: translateY(-1px);
        position: relative;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          @include transition;

          * {
            opacity: 1;
            @include transition;
          }

          &:nth-child(1) {
            opacity: 1;
          }

          &:nth-child(2) {
            opacity: 0;
          }
        }
      }
    }

    .label {
      padding-left: 12px;
      z-index: 0;
      text-align: center;
      font-family: $font;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      color: #1E1F20;
      @include transition;
    }

    &:hover {
      .wrapper {
        background-color: transparent;
      }

      .label {
        color: #fff;
      }

      .icon {
        svg {
          &:nth-child(1) {
            opacity: 0;
          }

          &:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }

  .footer-copyright {
    text-align: center;
    display: block;
    font-family: $font;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.2px;
    color: $color-grey;
  }

  .footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
      object-fit: contain;
    }
  }

  @media (max-width: 660px) {
    height: auto;
    max-width: 100%;
    padding: 40px 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .footer-copyright {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

}