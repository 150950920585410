@media (max-width: 375px) {
  .player-open .player-open-section {
    max-height: 40vh;
  }

  .book-card-grid {
    grid-template-columns: 100%;
  }
  .browse-image {
    width: 100%;
    height: 129px;
  }

  .category-splash {
    height: 129px;
  }

  .browse-item {
    width: 100%;
    text-align: center;
  }

  .browse-item p {
    display: block;
    text-align: center;
    width: 100%;
  }
  .category-section-header {
    flex-direction: column;
  }

  .sort-toggle {
    margin: -20px 0 20px;
    width: 99%;
    @supports (-ms-ime-align: auto) {
      width: 99%;
    }
  }
  .sort-toggle div {
    flex: 1;
  }
  .abl-image {
    min-width: 72px;
  }

  .fyh-fb {
    display: flex;
  }

  // .book-card-cover {
  //   width: 90px;
  //   height: 140px;
  // }

  .bcr-bottom img {
    width: 27px;
  }
  .playlist-preview {
    width: 140px;
    height: 190px;
  }
  .delete-np {
    margin-right: 6px;
  }
  .notification {
    width: 290px;
  }

  .notif-content {
    padding-right: 12px;
  }
  .mms-big-pic {
    width: 75px;
    height: 75px;
  }
}
