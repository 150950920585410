.book-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.book-card {
  display: flex;
  flex: 1;
  position: relative;
  // cursor: pointer;
  transition: 200ms ease opacity;
  will-change: opacity;
  text-decoration: none;
  color: inherit;
  &:hover {
    .book-card-cover {
      opacity: 0.7;
    }
  }
}

.book-card-cover {
  width: 135px;
  flex-shrink: 0;
  overflow: hidden;
  img {
    border-radius: 14px;
    max-width: 100%;
  }
}

.bc-right {
  box-sizing: border-box;
  padding: 16px 0 16px 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 420px) {
    flex-direction: column !important;
  }
}

.bcr-rating {
  font-size: 24px;
  font-weight: bold;
  color: #ff6b56;
  margin-bottom: 2px;
  display: block;
}

.bcr-title {
  font-size: 16px;
  color: #212126;
  margin-bottom: 4px;
  text-decoration: none;
  font-weight: 700;
}

.bcr-title-link {
  text-decoration: none;
}

.bcr-author {
  font-size: 16px;
  margin-bottom: 4px;
  color: #bbbebf;
}

.bcr-bottom {
  display: flex;
  img {
    margin-right: 16px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
}

.details-svg {
  position: absolute;
  right: -13px;
  top: 0;
  cursor: pointer;
  width: 12px;
}

.book-card-menu,
.author-menu {
  position: absolute;
  right: -15px;
  transform: translateX(100%);
  background-color: white;
  border-radius: 8px;
  width: 250px;
  z-index: 1;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.book-card-menu-top a,
.bcmt-content,
.bcm-share-link {
  padding: 12px 24px;
  color: #1e1f20;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:hover {
    font-weight: bold;
    > div {
      font-weight: normal;
    }
  }
}

.bcm-share-link {
  padding: 24px;
}

.book-card-menu-top {
  padding: 12px 0;
  border-bottom: 1px solid #e5e7ea;
}

.playlist-dropdown,
.share-dropdown {
  position: absolute;
  right: 0;
  transform: translateX(100%);
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  top: -2px;
  width: 250px;
  border-radius: 0 8px 8px 8px;
  display: none;
}

.share-dropdown {
  padding: 12px 0;
  a,
  .shd-bottom span {
    display: flex;
    padding: 16px 16px;
    align-items: center;
    text-decoration: none;
    color: inherit;
    &:hover {
      font-weight: bold;
    }
    svg {
      margin-right: 12px;
    }
  }
}

.plist-dropdown-bottom,
.shd-bottom {
  border-top: 1px solid #e5e7ea;
  padding: 12px 0;
}

.shd-bottom {
  padding-bottom: 0;
}

.pd-newlist svg,
.pd-item svg {
  margin-right: 12px;
}

.pd-item {
  justify-content: flex-start;
  padding: 16px 16px;
  display: flex;
  align-items: center;
  span:hover {
    font-weight: bold;
  }
}

.playlist-hover:hover {
  .playlist-dropdown {
    display: block;
  }
}

.bcm-share-link:hover {
  .share-dropdown {
    display: block;
  }
}

.pd-newlist {
  padding: 18px 16px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  a,
  span {
    padding: 0;
  }
}

.bcm-flip {
  right: 15px;
  transform: unset;

  .book-card-menu-top a,
  .bcmt-content,
  .bcm-share-link {
    flex-direction: row-reverse;
  }

  .arrow-right {
    transform: rotate(180deg);
  }

  .playlist-dropdown,
  .share-dropdown {
    right: unset;
    left: 0;
    transform: translateX(-100%);
  }

  a.pd-newlist {
    flex-direction: row;
  }
}

.author-details {
  flex: 1;
  padding: 24px 0 24px 64px;
}

.author-details-header {
  display: flex;
  justify-content: space-between;
}

.adh-name-books h1 {
  text-transform: capitalize;
  margin-bottom: 20px;
}

.adh-num-books {
  color: #bbbebf;
}

.adh-view-all {
  font-size: 12px;
  height: 40px;
  width: 150px;
  border: 1px solid #dee2e5;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.adh-view-all svg {
  position: absolute;
  right: 14px;
  width: 12px;
  height: 9px;
  top: 50%;
  transform: translateY(-50%);
}

.author-book-cards {
  margin-top: 40px;
}

.bcr-bottom-left {
  display: flex;
  flex-wrap: wrap;
}

.category-label {
  border-radius: 20px;
  padding: 14px 21px;
  font-size: 12px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  &:hover {
    opacity: 0.8;
  }
}

.bcr-bottom-left .category-label:nth-child(1) {
  background-color: #f0eaff;
}

.bcr-bottom-left .category-label:nth-child(2) {
  background-color: #ffeaf6;
}

.bcr-bottom-left .category-label:nth-child(3) {
  background-color: #eafffa;
}

.bcr-bottom-left .category-label:nth-child(4) {
  background-color: antiquewhite;
}

.bcr-bottom-left .category-label:last-of-type {
  margin-right: 0;
}

.book-card-holder-long {
  margin-bottom: 30px;
  .bc-right {
    flex: 1;
  }

  .bcr-bottom {
    justify-content: space-between;
  }

  .details-svg {
    position: relative;
    top: unset;
    right: unset;
    margin-left: 30px;
  }

  .bcr-bottom-right {
    display: flex;
    align-items: center;
  }

  .bcm-flip {
    right: 50px;
    bottom: 25px;
  }
}

.open-nav {
  z-index: 3;
}

.real-playlist-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
}

.real-playlist-cover img {
  max-width: 100%;
  max-height: 100%;
}

.book-page .browse-more-section {
  margin-bottom: 0 !important;
}

.book-page {
  .phr-dets {
    position: relative;
  }
  .bcm-flip {
    right: 0;
    top: 48px;
  }
  overflow-y: hidden;
}

// Reader
// -------------

.standard-page.book-page {
  .book-description:last-child {
    padding: 0;
  }
  .book-description{
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    h3 {
      font-weight: 300;
      margin-bottom: 15px;
      margin-top: 15px;
      text-align: center;
      line-height: 35px;
      color: #172B61;
    }

    .enumeration {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: #172B61;
    }

    .enumeration span {
      margin-bottom: 15px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      font-family: initial;
    }

    .enumeration p {
      margin-bottom: 10px;
    }


    .separator svg{
      margin: 15px auto 28px auto;
      width: 100%;
      text-align: center;
    }

    p.paragraph {
      color: #404040;
      margin-bottom: 0;
      b {
        font-weight: bold;
      }
      i {
        font-style: italic;
      }
    }

    p.legal-note {
      background-color: #f4f4f4;
      color: #b7b5b5;
      margin-top: 15px;
      padding: 12px;
      text-align: justify;
      line-height: 25px;
      font-style: italic;
    }
  }
}
