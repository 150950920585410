@media (max-width: 1024px) {
  .side-header {
    width: 190px;
  }

  .header-left {
    width: 190px;
  }

  .header-search-input {
    font-size: 14px;
  }

  .name-and-drop {
    margin-left: 8px;
  }

  .name-drop-arrow {
    margin-left: 7px;
  }

  .header-notifs {
    margin-left: 16px;
  }

  // .book-card-cover {
  //   width: 109px;
  // }

  .bcr-rating {
    font-size: 20px;
  }

  .bcr-title {
    font-size: 16px;
  }

  .bc-right {
    padding: 16px;
  }

  .bcr-bottom img {
    margin-right: 8px;
    width: 27px;
  }

  .bcr-author {
    font-size: 14px;
  }

  .details-svg {
    top: 0;
    padding: 14px;
    // right: 10%;
  }
  .category-label {
    font-size: 10px;
    padding: 7px 14px;
    // margin-right: 6px !important;
  }
  .pop-books-scroller {
    .bcr-top {
      flex: 1;
    }
  }
  .h-form-group {
    width: 300px;
    margin-bottom: 12px;
    margin-right: 1%;
  }

  .profile-header-left h1 {
    font-size: 21px;
  }

  .p-profile-picture {
    min-width: 72px;
  }

  .np-right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .np-right-buttons {
    flex-direction: column;
  }

  .delete-np {
    margin-bottom: 24px;
  }

  .playlist-input {
    width: 100%;
  }
  .browse-grid {
    // grid-template-columns: repeat(2, 50%);
    // .browse-item {
    //   &:nth-of-type(4n- 3) {
    //     justify-self: flex-start;
    //   }
    //   &:nth-of-type(4n- 4) {
    //     justify-self: flex-start;
    //   }
    //   &:nth-of-type(3n- 2) {
    //     justify-self: flex-start;
    //   }
    //   &:nth-of-type(3n - 3) {
    //     justify-self: flex-start;
    //   }
    //   &:nth-of-type(2n - 2) {
    //     justify-self: flex-end;
    //   }
    // }
  }

  .playlist-items {
    display: grid;
    grid-template-columns: repeat(2, calc(100% / 2));
    grid-gap: 36px 0;
  }
  .playlist-preview-box {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    position: relative;
    justify-self: center;
    &:nth-child(3n - 2) {
      justify-self: center;
    }
    &:nth-child(3n - 3) {
      justify-self: center;
    }
    &:nth-child(2n - 1) {
      justify-self: flex-start;
    }
    &:nth-child(2n - 2) {
      justify-self: flex-end;
    }
    &:hover {
      .playlist-preview {
        opacity: 0.5;
      }
      .pti-trash {
        display: flex;
      }
    }
  }
}
