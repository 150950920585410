.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-arrowright {
  width: 0.599609375em;
}

.icon-arrowup {
  width: 1.5556640625em;
}

.icon-authors {
  width: 0.875em;
}

.icon-browseleft {
  width: 1.142578125em;
}

.icon-checkmark {
  width: 1.375em;
}

.icon-checkw {
  width: 1.375em;
}

.icon-delete {
  width: 0.8330078125em;
}

.icon-details {
  width: 0.3330078125em;
}

.icon-dots {
  width: 7em;
}

.icon-downarrow {
  width: 1.6669921875em;
}

.icon-longarrow {
  width: 1.400390625em;
}

.icon-next {
  width: 1.099609375em;
}

.icon-person {
  width: 0.875em;
}

.icon-playw {
  width: 0.7998046875em;
}

.icon-previous {
  width: 1.099609375em;
}

.icon-rightarrow {
  width: 1.2998046875em;
}

.icon-trash {
  width: 0.8330078125em;
}

.icon-trashw {
  width: 0.8330078125em;
}

.icon-wave {
  width: 9.06640625em;
}

