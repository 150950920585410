@media (max-width: 1820px) {
  // .book-card-cover {
  //   width: 149px;
  // }

  .browse-item {
    // width: 298px;
    max-width: 100%;
  }

  .fyi-image,
  .browse-image {
    height: 160px;
    border-radius: 64px;
  }

  .category-splash {
    height: 160px;
  }

  .player {
    width: 340px;
  }

  .pp-active-title {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }
  .browse-grid {
    // .browse-item {
    //   &:nth-of-type(4n- 3) {
    //     justify-self: flex-start;
    //     display: flex;
    //     .browse-image {
    //       align-self: flex-start;
    //     }
    //   }
    //   &:nth-of-type(4n- 4) {
    //     justify-self: flex-end;
    //     display: flex;
    //     .browse-image {
    //       align-self: flex-end;
    //     }
    //   }
    // }
  }
  .playlist-items {
    display: grid;
    grid-template-columns: repeat(4, calc(100% / 4));
    grid-gap: 36px 0;
  }
  .playlist-preview-box {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    position: relative;
    justify-self: center;
    &:nth-child(6n - 5) {
      justify-self: center;
    }
    &:nth-child(6n - 6) {
      justify-self: center;
    }
    &:nth-child(4n - 3) {
      justify-self: flex-start;
    }
    &:nth-child(4n - 4) {
      justify-self: flex-end;
    }
    &:hover {
      .playlist-preview {
        opacity: 0.5;
      }
      .pti-trash {
        display: flex;
      }
    }
  }
}
