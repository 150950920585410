.mobile-bottom-header {
  display: none;
}

.book-header-mobile-links {
  display: none;
}

@import "./responsive1820.scss";
@import "./responsive1440.scss";
@import "./responsive1024.scss";
@import "./responsive768.scss";
@import "./responsive375.scss";
